import React from "react";
import HomeHeroSection from "../components/Home/HomeHeroSection";
import AboutUsSection from "../components/Home/AboutUsSection";
import OurFacilitySection from "../components/Home/OurFacilitySection";
import GetInTouch from "../components/Home/GetInTouch";
import BlogSection from "../components/Home/BlogSection";
import ServicesSection from "../components/Common/ServicesSection";
import OurSpecialListSection from "../components/Common/OurSpecialListSection";
import ReviewSection from "../components/Common/ReviewSection";
import Navbar from "../components/Common/Navbar";
import Footer from "../components/Common/Footer";

export default function Home() {
  return (
    <div className="flex flex-col w-full ">
      <HomeHeroSection />
      <AboutUsSection />
      <OurFacilitySection />
      <ServicesSection />
      <OurSpecialListSection />
      <ReviewSection />
      <GetInTouch />
      <BlogSection />
    </div>
  );
}
