import React from 'react'

export default function Icons(props) {


  return (
    <div className=''>
      <img src={`/Assert/${props.value}-Icon.svg`} className={props.css}  alt="" />
    </div>
  )
}
