import React from 'react'
import Navbar from "../components/Common/Navbar";
import Footer from "../components/Common/Footer";
import HeroSection from "../components/Common/HeroSection";
import ContactForm from "../components/Contact/ContactForm";
import ContactAddress from "../components/Contact/ContactAddress";

function ContactUs() {
  return (
    <div>
      <HeroSection title="Contact Us" />
      <ContactForm />
      <ContactAddress />
    </div>
  );
}

export default ContactUs