import React from "react";
import ReviewCard from "../../layouts/ReviewCard";
import { useMediaQuery } from "react-responsive";

function MainReviewSection() {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const aboutUsCards = [
    {
      title: "B/o Bhavini Dave",
      branch: "Nicu",
      description:
        "We had a miracle baby in hands after 76 days of stay in NICU. We must say that we experienced.",
    },
    {
      title: "Hanna Carder",
      branch: "Hospital",
      description:
        "Best hospital in surat. Dr. Chetan Shah is a good pediatrician doctor for child. good service.",
    },
    {
      title: "Hanna Carder",
      branch: "Hospital",
      description:
        "Best hospital in surat. Dr. Chetan Shah is a good pediatrician doctor for child. good service.",
    },
    {
      title: "Hanna Carder",
      branch: "Hospital",
      description:
        "Best hospital in surat. Dr. Chetan Shah is a good pediatrician doctor for child. good service.",
    },
    {
      title: "Hanna Carder",
      branch: "Hospital",
      description:
        "Best hospital in surat. Dr. Chetan Shah is a good pediatrician doctor for child. good service.",
    },
    {
      title: "Hanna Carder",
      branch: "Hospital",
      description:
        "Best hospital in surat. Dr. Chetan Shah is a good pediatrician doctor for child. good service.",
    },
    {
      title: "Hanna Carder",
      branch: "Hospital",
      description:
        "Best hospital in surat. Dr. Chetan Shah is a good pediatrician doctor for child. good service.",
    },
    {
      title: "Hanna Carder",
      branch: "Hospital",
      description:
        "Best hospital in surat. Dr. Chetan Shah is a good pediatrician doctor for child. good service.",
    },
    // Add more cards...
  ];

  const filteredCards = isMobile ? aboutUsCards.slice(0, 4) : aboutUsCards;

  return (
    <div className="flex items-center justify-center w-full h-auto  flex-col p-2">
      <div className=" ss">
        <h1 className="pt-4 sm:pt-1 text-4xl sm:text-[20px] text-center text-blue-700">
          Review
        </h1>
        <p className="text-center sm:text-[12px] text-gray-500 sm:p-0 p-4 text-[20px] mb-[1em] w-[30em] md:w-[37em] mx-auto">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ipsum
          neque, suscipit sit amet massa eu, pretium dictum massa.
        </p>
      </div>

      <div className="flex md:flex-wrap flex-wrap justify-center flex-row">
        {filteredCards.map((card, index) => (
          <ReviewCard
            key={index}
            title={card.title}
            branch={card.branch}
            description={card.description}
          />
        ))}
      </div>
      <div className="flex text-center items-center justify-center mt-3">
        <button className="bg-[#4CAF50] w-[10rem] xl:h-[45px] mt-4 p-1 text-center justify-center rounded-3xl text-white font-Poppins text-20 font-semibold">
          Load More ➡
        </button>
      </div>
    </div>
  );
}

export default MainReviewSection;
