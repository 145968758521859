import React from 'react'
import HeroSection from '../components/Common/HeroSection'
import Specialistdata from '../components/SpeciallistData/Specialistdata'


export default function SpeacialistDetail() {
  return (
    <>
    {/* <HeroSection title="Our Specialists"/> */}
    <Specialistdata/>
    
    </>
  )
}
