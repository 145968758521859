import React from 'react'
import { useParams } from "react-router-dom"

export default function Specialistdata() {
    const { id } = useParams();

    const profileDataArray = [
        {
            imageUrl: '/assert/Specilist_ProfilePic.svg',
            name: 'Dr. John Doe',
            Speciality: 'Child Specialist',
            Deegrees: 'MD (Pediatrics), DCH',
            Experience: '31 Years of Experience',
            Training: 'Dr. Chetan B. Shah is the Chairman of Anand Children Hospital, Nanpura and Vesu Branch and is working as Consultant Pediatrician and Neonatologist since last 31 Years. Anand Children Hospital is one of the finest Pediatric Super Specialty Hospital of Gujarat State. Under the supervision of Dr. Chetan B. Shah, Anand Children Hospital manages Pediatric ICU (PICU) and Neonatal ICU (NICU) at Navsari and Bharuch Cities. Dr. Chetan B. Shah is currently President of Surat Medical Consultants’ Association & Executive Committee Member of Central IAP for the year 2016. He was also the Past President of Academy of Pediatrics, Gujarat State Branch and also Past President of Surat Pediatric Association. He heads 2 Fellowship Programs in Neonatology at this centre. He is one of the Pioneers in foundation of first Human Milk Bank known as “Yasodha Milk Bank”. He has convened and Chaired many State level and National Level conferences and has delivered many lectures in State Level and National Level Conferences. He has also done various charity for poor and needy patients.',
        },
        {
            imageUrl: '/assert/Specilist_ProfilePic.svg',
            name: 'Dr. John s',
            Speciality: 'Child Specialist',
            Deegrees: 'MD (Pediatrics), DCH',
            Experience: '31 Years of Experience',
            Training: '...Your training details...',
        },
    ];

    return (
        <div className='flex items-center justify-center w-full h-[120vh]'>
            <div className='w-[80%] sm:w-[100%] h-[90%]  flex-col sm:items-center sm:justify-center  flex '>
                <div className='w-[100%] sm:w-[80%] sm:h-auto h-[15%] sm:mb-4 sm:justify-center sm:items-center'>
                    <h1 className="text-4xl sm:text-[20px] font-semibold leading-10 tracking-wider text-center text-blue-600 font-poppins">
                        Our Services
                    </h1>

                    <p className="text-base font-normal sm:text-[12px] sm:text-[#333333] leading-6 text-center text-gray-600 font-poppins">
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum is simply.
                    </p>
                </div>
                <div className='w-[100%]  h-[85%] sm:justify-center sm:items-center  flex flex-row sm:flex-col gap-6'>
                    <div className='w-[50%] sm:w-[100%] sm:h-[80%] h-[100%]  '>
                        <img src={profileDataArray[id-1].imageUrl} className='w-[100%] sm:h-[80%] h-[80%]' alt="" />
                    </div>
                    <div className='w-[50%] sm:w-[80%]  sm:justify-center sm:items-center h-[100%]  '>
                        <h3 className="sm:text-left text-blue-40 sm:mt-0 sm:text-[20px] text-[30px] ">{profileDataArray[id-1].name}</h3>
                        <hr className="w-[15em] " />
                        <div className='text-[20px] sm:text-left sm:flex flex-row gap-20'>
                            <h3 className='mt-4 sm:mt-0 text-black sm:text-[#2D7AB3] sm:text-[20px]'>Speciality</h3>
                            <p className='text-gray-400 sm:ml-0 sm:mt-[0] ml-[9em] mt-[-1.5em]' >{profileDataArray[id-1].Speciality}</p>
                        </div>
                        <hr className="w-[15em] " />
                        <div className='text-[20px] flex-row sm:flex gap-20 '>
                            <h3 className='mt-4 sm:mt-0 text-black sm:text-[#2D7AB3] sm:text-[20px]'>Deegrees</h3>
                            <p className='text-gray-400 sm:ml-0 ml-[9em] sm:mt-0 mt-[-1.5em]'>{profileDataArray[id-1].Deegrees}</p>
                        </div>
                        <hr className="w-[15em] " />
                        <div className='text-[20px] flex-row sm:flex gap-20 '>
                            <h3 className='mt-4 sm:mt-0 text-black sm:text-[#2D7AB3] sm:text-[20px]'>Experience</h3>
                            <p className='text-gray-400 ml-[9em] sm:ml-0 sm:mt-0  mt-[-1.5em]'>{profileDataArray[id-1].Experience}</p>
                        </div>

                        <hr className="w-[15em] " />
                        <div>
                            <h3 className='mt-4 sm:text-left sm:mt-0 text-black sm:text-[#2D7AB3] sm:text-[20px]'>Training</h3>
                            <p className='text-gray-400 text-[16px] sm:w-[100%]   w-[40em]'>{profileDataArray[id-1].Training}</p>
                        </div>
                        <hr className="w-[15em] " />
                    </div>
                </div>
            </div>

            {/* <div className="col-start-1 col-end-3 mb-3 bg-black">
        <h1 className="text-4xl font-semibold leading-10 tracking-wider text-center text-blue-600 font-poppins">
          Our Services
        </h1>

        <p className="text-base font-normal leading-6 text-center text-gray-600 font-poppins">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum is simply.
        </p>
      </div> */}

            {/* <div className='pr-2 ml-9 pl-[8em]'>
   <div className="flex items-center content-side">
    
    <div className="image-container border-blue-400 mr-[6em]">
    <img
        className="w-[100%] "
        src={profileDataArray[id-1].imageUrl}
        alt={`${profileDataArray[id-1].name}'s profile picture`}
      />
    </div>
    <div className="text-container ">
      <h3 className="text-blue-400">{profileDataArray[id-1].name}</h3>
      <hr className="w-[15em] mt-[1em]" />
      <div>
      <h3 className='mt-4 text-black'>Speciality</h3>
      <p className='text-gray-400 ml-[9em] mt-[-1.5em]' >{profileDataArray[id-1].Speciality}</p>
      </div>
      <hr className="w-[15em] mt-[1em]" />
      <div>
      <h3 className='mt-4 text-black'>Deegrees</h3>
      <p className='text-gray-400 ml-[9em] mt-[-1.5em]'>{profileDataArray[id-1].Deegrees}</p>
      </div>
      <hr className="w-[15em] mt-[1em]" />
      <div>
      <h3 className='mt-4 text-black'>Experience</h3>
      <p className='text-gray-400 ml-[9em] mt-[-1.5em]'>{profileDataArray[id-1].Experience}</p>
      </div>
    
      <hr className="w-[15em] mt-[1em]" />
      <div>
      <h3 className='mt-4 text-black'>Training</h3>
      <p className='text-gray-400 w-[40em]'>{profileDataArray[id-1].Training}</p>
      </div>
      <hr className="w-[15em] mt-[1em]" />
    </div>
  </div>
    </div> */}
        </div>
    )
}
