import React from 'react'
import { CiLocationOn } from "react-icons/ci";
import Icons from "../../layouts/Icons"

function ContactAddress() {
  return (
    <div className='w-full h-[600px] items-center flex justify-start '>
      <div className='w-full h-[80%] bg-[#FBFCFD] sm:bg-transparent flex justify-center relative text-[20px] items-center'>
        <div className='w-[80%] flex flex-col sm:flex-row flex-wrap gap-6'>
        <div className='flex justify-around sm:flex-col '>
          <div className="flex flex-row mt-4   ">
          <Icons value="Location" css="w-[40px]" />
              <span className="w-[60%] sm:w-[100%] text-[18px] sm:text-[12px] sm:text-left font-poppins font-normal leading-30 tracking-normal ml-2 text-gray-500   block">
                1/568 B Por Mohallo, Athugar Street, Near Kailash Restaurant,
                Nanpura, Surat - 395001
              </span>
            </div>
            <div className="flex flex-row mt-4   ">
            <Icons value="Location" css="w-[40px]" />
              <span className=" w-[60%] sm:w-[100%] text-left text-[18px] sm:text-[12px] font-poppins font-normal leading-30 tracking-normal ml-2 text-gray-500   block">
                1/568 B Por Mohallo, Athugar Street, Near Kailash Restaurant,
                Nanpura, Surat - 395001
              </span>
            </div>
            <hr className='xl:hidden mt-2' />
            <div className="flex flex-row mt-4   ">
            <Icons value="Location" css="w-[40px]" />
              <span className="w-[60%] sm:w-[100%] text-left text-[18px] sm:text-[12px] font-poppins font-normal leading-30 tracking-normal ml-2 text-gray-500   block">
                1/568 B Por Mohallo, Athugar Street, Near Kailash Restaurant,
                Nanpura, Surat - 395001
              </span>
            </div>
          </div>
          <div className='flex justify-around  sm:flex-col'>
          <div className="flex flex-row mt-4 sm:mt-0   ">
          <Icons value="Location" css="w-[40px]" />
              <span className="w-[60%] sm:w-[100%] text-[18px] sm:text-[12px] sm:text-left font-poppins font-normal leading-30 tracking-normal ml-2 text-gray-500   block">
                1/568 B Por Mohallo, Athugar Street, Near Kailash Restaurant,
                Nanpura, Surat - 395001
              </span>
            </div>
            <hr className='xl:hidden mt-2' />
            <div className="flex flex-row mt-4   ">
            <Icons value="Location" css="w-[40px]" />
              <span className=" w-[60%] sm:w-[100%] text-left text-[18px] sm:text-[12px] font-poppins font-normal leading-30 tracking-normal ml-2 text-gray-500   block">
                1/568 B Por Mohallo, Athugar Street, Near Kailash Restaurant,
                Nanpura, Surat - 395001
              </span>
            </div>
            <div className="flex flex-row mt-4   ">
            <Icons value="Location" css="w-[40px]" />
              <span className="w-[60%] sm:w-[100%] text-left text-[18px] sm:text-[12px] font-poppins font-normal leading-30 tracking-normal ml-2 text-gray-500   block">
                1/568 B Por Mohallo, Athugar Street, Near Kailash Restaurant,
                Nanpura, Surat - 395001
              </span>
            </div>
            <hr className='xl:hidden mt-2' />
          </div>
          
          <div className='flex justify-center sm:w-[100%]  sm:flex-col items-center sm:items-center flex-row sm:gap-3 gap-10 '>
          <div className="flex flex-row  mt-4 sm:mt-0  sm:items-center ">
          <Icons value="email" css="w-[30px]" />
              <span className=" w-[60%] text-left sm:w-[100%] text-[18px] sm:text-[12px] font-poppins font-normal leading-30 tracking-normal sm:ml-0 ml-2 text-gray-500   block">
                Moanandchildrenhospital@yahoo.com
              </span>
            </div>
            <div className="flex flex-row mt-4  sm:mt-0 sm:items-center  ">
            <Icons value="website" css="w-[40px]" />
              <span className=" w-[60%] sm:w-[100%] sm:text-[12px] text-left text-[18px]  font-poppins font-normal leading-30 tracking-normal ml-2 text-gray-500   block">
              www.anandchildrenhospital.in
              </span>
            </div>
          </div>
        </div>
        <img src="/Assert/Contact-us-svg.svg" alt="sssssssss" className='w-[400px] sm:hidden absolute bottom-0 left-0 ' />
      </div>
    
    </div>
  )
}

export default ContactAddress