import React from "react";
import GetinTouchForm from "../../layouts/GetinTouchForm";

function ContactForm() {
  return (
    <div className="flex justify-center items-center flex-col ">
      <div>
        <h1 className="text-4xl text-center text-blue-700 font-bold sm:pt-2 sm:text-[20px] pt-4">
          Contact Us
        </h1>
        <p className="text-center  text-gray-500 p-4 sm:p-1 sm:text-[12px] sm:mb-0 mb-[4em] w-[30em] md:w-[37em] mx-auto">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ipsum
          neque, suscipit.
        </p>
      </div>
      <div className=" w-[80%] sm:w-[90%] bg-[#FBFCFD] h-[500px] p-18 flex justify-center items-center ">
        <GetinTouchForm />
      </div>
    </div>
  );
}

export default ContactForm;
