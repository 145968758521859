import React from "react";
import { Link } from "react-router-dom";

export default function MainBlogSection() {
  return (
    <div className="flex items-center justify-center text-center">
      <div className="grid grid-cols-3 sm:grid-cols-1 lg:grid-cols-2  sm:items-center sm:justify-center gap-3 sm:w-[100%] w-[80%] my-3 ">
        <div className="col-span-3 ss">
          <h1 className="pt-4 sm:pt-0 font-bold  sm:text-[20px] text-4xl text-center text-blue-700">
            Blogs
          </h1>
          <p className="text-center text-[12px]  text-gray-500 sm:p-0 p-4 sm:mb-0 mb-[4em] w-[30em] md:w-[37em]  mx-auto">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
            ipsum neque, suscipit sit amet massa eu, pretium dictum massa.
          </p>
        </div>
        <div className="sm:w-full sm:items-center sm:flex sm:justify-center">
          <div className="bg-[#F6FBFF] sm:w-[80%] rounded-2xl flex items-center justify-center text-center ">
            <div className="grid-cols-1 grid w-[80%] sm:gap-2 lg:gap-6 sm:h-[100%] h-[90%]">
              <div className="flex flex-row w-full text-left sm:mt-4 ">
                <div className="w-full text-left flex flex-row gap-2 ">
                  <div>
                    <img
                      src="/Assert/HomeBlog1.1.svg"
                      alt=""
                      className="w-[100px] sm:w-[70px]"
                    />
                  </div>
                  <div className="ml-2 gap-2">
                    <h3 className="text-[20px] sm:text-[14px] text-[#2D7AB3] font-bold font-poppins ">
                      Pediatric Infectious Diseas
                    </h3>
                    <h4 className="text-[16px] sm:text-[10px]">25 May, 2023</h4>
                    <div className="flex items-center">
                      <Link
                        to="#"
                        className="text-green-600 hover:underline text-[22px] sm:text-[10px]"
                      >
                        View Profile --
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="flex flex-row w-full text-left ">
                <div className="w-full text-left flex flex-row gap-2 ">
                  <div>
                    <img
                      src="/Assert/HomeBlog1.1.svg"
                      alt=""
                      className="w-[100px] sm:w-[70px]"
                    />
                  </div>
                  <div className="ml-2 gap-2">
                    <h3 className="text-[20px] sm:text-[14px] text-[#2D7AB3] font-bold font-poppins ">
                      Pediatric Infectious Diseas
                    </h3>
                    <h4 className="text-[16px] sm:text-[10px]">25 May, 2023</h4>
                    <div className="flex items-center">
                      <Link
                        to="#"
                        className="text-green-600 hover:underline text-[22px] sm:text-[10px]"
                      >
                        View Profile --
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="flex flex-row w-full text-left ">
                <div className="w-full text-left flex flex-row gap-2 ">
                  <div>
                    <img
                      src="/Assert/HomeBlog1.1.svg"
                      alt=""
                      className="w-[100px] sm:w-[70px]"
                    />
                  </div>
                  <div className="ml-2 gap-2">
                    <h3 className="text-[20px] sm:text-[14px] text-[#2D7AB3] font-bold font-poppins ">
                      Pediatric Infectious Diseas
                    </h3>
                    <h4 className="text-[16px] sm:text-[10px]">25 May, 2023</h4>
                    <div className="flex items-center">
                      <Link
                        to="#"
                        className="text-green-600 hover:underline text-[22px] sm:text-[10px]"
                      >
                        View Profile --
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="flex flex-row w-full text-left ">
                <div className="w-full text-left flex flex-row gap-2 ">
                  <div>
                    <img
                      src="/Assert/HomeBlog1.1.svg"
                      alt=""
                      className="w-[100px] sm:w-[70px]"
                    />
                  </div>
                  <div className="ml-2 gap-2">
                    <h3 className="text-[20px] sm:text-[14px] text-[#2D7AB3] font-bold font-poppins ">
                      Pediatric Infectious Diseas
                    </h3>
                    <h4 className="text-[16px] sm:text-[10px]">25 May, 2023</h4>
                    <div className="flex items-center">
                      <Link
                        to="#"
                        className="text-green-600 hover:underline text-[22px] sm:text-[10px]"
                      >
                        View Profile --
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="flex flex-row w-full text-left ">
                <div className="w-full text-left flex flex-row gap-2 ">
                  <div>
                    <img
                      src="/Assert/HomeBlog1.1.svg"
                      alt=""
                      className="w-[100px] sm:w-[70px]"
                    />
                  </div>
                  <div className="ml-2 gap-2">
                    <h3 className="text-[20px] sm:text-[14px] text-[#2D7AB3] font-bold font-poppins ">
                      Pediatric Infectious Diseas
                    </h3>
                    <h4 className="text-[16px] sm:text-[10px]">25 May, 2023</h4>
                    <div className="flex items-center">
                      <Link
                        to="#"
                        className="text-green-600 hover:underline text-[22px] sm:text-[10px]"
                      >
                        View Profile --
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="flex flex-row w-full sm:mb-4 text-left ">
                <div className="w-full text-left flex flex-row gap-2 ">
                  <div>
                    <img
                      src="/Assert/HomeBlog1.1.svg"
                      alt=""
                      className="w-[100px] sm:w-[70px]"
                    />
                  </div>
                  <div className="ml-2 gap-2">
                    <h3 className="text-[20px] sm:text-[14px] text-[#2D7AB3] font-bold font-poppins ">
                      Pediatric Infectious Diseas
                    </h3>
                    <h4 className="text-[16px] sm:text-[10px]">25 May, 2023</h4>
                    <div className="flex items-center">
                      <Link
                        to="#"
                        className="text-green-600 hover:underline text-[22px] sm:text-[10px]"
                      >
                        View Profile --
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:hidden md:hidden lg:hidden sm:w-full justify-center gap-4 items-center flex-col flex col-span-3">
          <div className="">
            <img
              src="/Assert/HomeBlog1Mobile.svg"
              alt=""
              loading="lazy"
              className="m-0  "
            />
          </div>
          <div>
            <img
              src="/Assert/HomeBlog2Mobile.svg"
              alt=""
              loading="lazy"
              className="m-0"
            />
          </div>
          <div className="col-span-2">
            <img
              src="/Assert/HomeBlog3Mobile.svg"
              alt=""
              loading="lazy"
              className=""
            />
          </div>
        </div>
        <div className="grid grid-cols-2 sm:hidden    sm:flex-col col-span-2 gap-3 ">
          <div>
            <img src="/Assert/HomeBlog1.svg" alt="" className="m-0 " />
          </div>
          <div>
            <img src="/Assert/HomeBlog2.svg" alt="" className="m-0" />
          </div>
          <div className="col-span-2">
            <img src="/Assert/HomeBlog3.svg" alt="" className="" />
          </div>
        </div>
      </div>
    </div>
  );
}
