import React from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
} from "@mui/material";
import ReviewCard from "../../layouts/ReviewCard";
import { useMediaQuery } from 'react-responsive';


function ReviewSection({ title, branch, description }) {

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const aboutUsCards = [
    {
      title: "B/o Bhavini Dave",
      branch: "Nicu",
      description:
        "We had a miracle baby in hands after 76 days of stay in NICU. We must say that we experienced.",
    },
    {
      title: "Hanna Carder",
      branch: "Hospital",
      description:
        "Best hospital in surat. Dr.chetan shah is a good pediatrician doctor for child. good service.",
    },
    {
      title: "Hanna Carder",
      branch: "Hospital",
      description:
        "Best hospital in surat. Dr.chetan shah is a good pediatrician doctor for child. good service.",
    },
    {
      title: "Hanna Carder",
      branch: "Hospital",
      description:
        "Best hospital in surat. Dr.chetan shah is a good pediatrician doctor for child. good service.",
    },
   
    
   
    // Add more cards...
  ];
  return (
    <div className="text-black p-2 mb-4">
      <h1 className="text-4xl text-center text-blue-700 pt-2">Review</h1>
      <p className="text-center  text-gray-500 p-4 mb-[1em] w-[37em] sm:w-full mx-auto">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ipsum
        neque, suscipit sit amet massa eu, pretium dictum massa.
      </p>

      <div className="flex md:flex-wrap justify-center flex-row">
  {aboutUsCards.map((card, index) => (
    // Conditionally render based on the breakpoint
    isMobile ? (
      index === 0 && (
        <ReviewCard
          key={index}
          title={card.title}
          branch={card.branch}
          description={card.description}
        />
      )
    ) : (
      // Render all cards for other screen sizes
      <ReviewCard
        key={index}
        title={card.title}
        branch={card.branch}
        description={card.description}
      />
    )
  ))}
</div>

      <div className="flex text-center items-center justify-center mt-3">
            <button className="bg-[#4CAF50] w-[10rem] xl:h-[40px] mt-2 p-1 text-center justify-center rounded-3xl text-white font-Poppins text-20 font-semibold">
              See More ➡
            </button>
          </div>
    </div>
  );
}

export default ReviewSection