import React from "react";
import { useMediaQuery } from "react-responsive";

export default function ReviewCard({ title, branch, description }) {
  return (
    <div className="flex flex-col m-1 bg-white rounded-2xl shadow-md sm:flex-col sm:w-[80%] lg:w-[30%]  xl:w-[20%] ">
      <div className="bg-blue h-[10em] xl:h-[12em] transform rotate-100 origin-bottom rounded-2xl">
        <div className="flex items-center justify-center h-[10em]">
          <img src="/assert/rating.svg" className="align-center" alt="Rating" />
        </div>
      </div>
      <div className="bg-white h-[4em] transform rotate-[9deg] origin-bottom ml-[-1em]"></div>
      <div className="bg-white h-[4em] transform rotate-[-189deg] origin-top mr-[-1em]"></div>
      <img
        src="/assert/Ellipse6.svg"
        alt="Centered Image"
        className="w-16 h-16 m-auto mt-[-10em] z-10 xl:mt-[-12em]  xl:w-[35%] xl:h-[35%]"
      />
      <div className="relative w-full p-4 text-center sm:w-[100%]">
        <img src="/assert/vector.svg"></img>
        <h2 className="z-10 mb-2 text-xl font-semibold text-blue-500 ">
          {title}
        </h2>
        <p className="z-10 mb-2 text-l">{branch}</p>
        <p className="z-10 text-gray-600 text-[18px] sm:text-[13px]">
          {description}
        </p>
      </div>
    </div>
  );
}
