import React from 'react'
import Navbar from "../components/Common/Navbar"
import Footer  from "../components/Common/Footer"
import HeroSection from "../components/Common/HeroSection";
import MainGallerySection  from "../components/Gallery/MainGallerySection"

function Gallery() {
  return (
    <div>
      <HeroSection title="Contact Us" />
      <MainGallerySection />
    </div>
  );
}

export default Gallery