import React from "react";
import { Link } from "react-router-dom";

function ServicesSection() {
  return (
    <div className=" flex justify-center item-center ">
      <div className="grid grid-cols-2 sm:grid-cols-1 bg-white w-[75%] md:w-[85%] sm:w-[85%] h-[90%] mt-9 m-3 ">
        <div className="col-start-1 col-end-3 sm:col-end-1 mb-3 ">
          <h1 className="text-center sm:text-[20px] text-blue-600 font-poppins font-semibold text-4xl leading-10 tracking-wider">
            Services
          </h1>
          <p className="text-center text-gray-600 font-poppins sm:text-[15px] xl:text-base font-normal leading-6">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum is simply.
          </p>
        </div>
        <div className="mt-4  ">
          <div className="  relative border-double border-2xl h-[220px] border-black">
            <div className="border-[#2D7AB3FF]   border-solid border-2 rounded-xl w-[30%] sm:w-[45%] md:w-[60%] lg:w-[45%] h-[100%] m-2 flex items-center  ">
              <h1 className="text-[#EFF4F8] font-inter  ml-5 text-5xl font-bold leading-none">
                01
              </h1>
            </div>
            <div className=" top-[55px] left-[120px] w-[60%] sm:w-[70%] md:w-[65%] sm:top-[40px] md:top-[30px] lg:top-[30px] absolute items-center bg-white">
              <h1 className="text-blue-600 font-poppins text-2xl sm:text-xl md:text-xl font-semibold leading-6 text-left">
                Operation Theater
              </h1>

              <p className="text-gray-500 m-0 my-1 font-poppins text-base sm:text-[12px] md:text-[12px] text-[18px] font-normal leading-6 text-left">
                Anand Children Hospital Immunization Clinic has provided
                vaccines to children in Surat since 1987. Our clinic offers a
                variety of vaccines for children, adults...
              </p>
              <div className="flex items-center">
                <Link to="#" className="text-green-600 hover:underline">
                  View Profile --
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 sm:hidden ">
          <div className="  relative border-double border-2xl h-[220px] border-black">
            <div className="border-[#2D7AB3FF]   border-solid border-2 rounded-xl w-[30%] md:w-[60%]  lg:w-[45%] h-[100%] m-2 flex items-center  ">
              <h1 className="text-[#EFF4F8] font-inter  ml-5 text-5xl font-bold leading-none">
                01
              </h1>
            </div>
            <div className=" top-[55px] left-[120px] w-[60%] md:w-[70%] md:top-[30px] lg:top-[30px] absolute items-center bg-white">
              <h1 className="text-blue-600 font-poppins text-2xl md:text-xl font-semibold leading-6 text-left">
                Operation Theater
              </h1>

              <p className="text-gray-500 m-0 my-1 font-poppins text-base text-[18px] md:text-[12px] font-normal leading-6 text-left">
                If you need a doctor for to consectetuer Lorem ipsum dolor,
                consectetur adipiscing elit Lorem ipsum dolor, consectetur Ut
                volutpat eros.
              </p>
              <div className="flex items-center">
                <Link to="#" className="text-green-600 hover:underline">
                  View Profile --
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 sm:hidden md:hidden">
          <div className="  relative border-double border-2xl h-[220px] border-black">
            <div className="border-[#2D7AB3FF]   border-solid border-2 rounded-xl w-[30%]  lg:w-[45%] h-[100%] m-2 flex items-center  ">
              <h1 className="text-[#EFF4F8] font-inter  ml-5 text-5xl font-bold leading-none">
                01
              </h1>
            </div>
            <div className=" top-[55px] left-[120px] w-[60%] lg:top-[30px] absolute items-center bg-white">
              <h1 className="text-blue-600 font-poppins text-2xl font-semibold leading-6 text-left">
                Operation Theater
              </h1>

              <p className="text-gray-500 m-0 my-1 font-poppins text-base text-[18px] font-normal leading-6 text-left">
                If you need a doctor for to consectetuer Lorem ipsum dolor,
                consectetur adipiscing elit Lorem ipsum dolor, consectetur Ut
                volutpat eros.
              </p>
              <div className="flex items-center">
                <Link to="#" className="text-green-600 hover:underline">
                  View Profile --
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 sm:hidden md:hidden">
          <div className="  relative border-double border-2xl h-[220px] border-black">
            <div className="border-[#2D7AB3FF]   border-solid border-2 rounded-xl w-[30%]  lg:w-[45%] h-[100%] m-2 flex items-center  ">
              <h1 className="text-[#EFF4F8] font-inter  ml-5 text-5xl font-bold leading-none">
                01
              </h1>
            </div>
            <div className=" top-[55px] left-[120px] w-[60%] lg:top-[30px] absolute items-center bg-white">
              <h1 className="text-blue-600 font-poppins text-2xl font-semibold leading-6 text-left">
                Operation Theater
              </h1>

              <p className="text-gray-500 m-0 my-1 font-poppins text-base text-[18px] font-normal leading-6 text-left">
                If you need a doctor for to consectetuer Lorem ipsum dolor,
                consectetur adipiscing elit Lorem ipsum dolor, consectetur Ut
                volutpat eros.
              </p>
              <div className="flex items-center">
                <Link to="#" className="text-green-600 hover:underline">
                  View Profile --
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:hidden md:hidden lg:hidden sm:flex relative text-center bottom-[-60px] sm:bottom-[-20px] items-center justify-center mt-3">
          <button className="bg-[#4CAF50]  w-[10rem] xl:h-[40px] mt-2 p-1 text-center justify-center rounded-3xl text-white font-Poppins text-20 font-semibold">
            Get a quote ➡
          </button>
        </div>
      </div>
    </div>
  );
}

export default ServicesSection;
