import React from 'react'
import { BsArrowRight } from "react-icons/bs";
import HomeBoxesSection from "../../layouts/HomeBoxesSection";

function AboutUsSection() {
  return (
    <div className=" text-center justify-center flex  ">
      <div className="grid grid-cols-2 sm:grid-cols-1  gap-4 m-4 justify-center sm:w-[80%] w-[70%] ">
        <div className="sm:w-[100%] sm:h-[55px]">
          <img
            src="/Assert/happy-female-doctor-giving-high-fie-little-boy-who-came-with-father-hospital 1.svg"
            alt=""
            className=''
          />
        </div>
        <div className="flex flex-col w-auto bg-white m-2 justify-center sm:text-center">
          <span className="font-Poppins text-[40px] font-semibold tracking-normal mb-1 text-left sm:text-center   text-[#2D7AB3]">
            ABOUT US
          </span>
          <span className=" font-sans leading-relaxed text-[#7F7F7F] text-[15px] sm:text-center text-left mb-3">
            Anand Children Hospital is one of the largest Pediatric Super
            Specialty Hospital of South Gujarat located at the center of the
            Surat City. It is the 1st Pediatric Hospital in Gujarat to get Level
            III (A) NNF (National Neonatology Forum) accreditation. With
            Advanced Neonatal ICU (NICU), Pediatric ICU (PICU), Operational
            Theatre for all Neonatal & Pediatric Surgeries...
          </span>
          <div className='flex justify-center items-center'>
            <button className="bg-[#4CAF50] w-[10rem] p-1 text-center sm:items-center justify-center rounded-2xl text-white font-Poppins text-18 font-semibold">
              Read More ➡
            </button>
          </div>
        </div>
        <div className="col-start-1 col-end-3 "><HomeBoxesSection /></div>
      </div>
    </div>
  );
}

export default AboutUsSection