import React from "react";

export default function GetinTouchForm() {
  return (
    <div className="flex flex-col items-center sm:w-[100%] md:w-full lg:w-full w-[60%]">
      <input
        type="text"
        placeholder="Name"
        className="w-[90%]  sm:h-[20%]  sm:w-[100%] my-3 rounded-xl bg-white"
      />
      <input
        type="text"
        placeholder="Email"
        className="w-[90%] my-3 sm:h-[20%] sm:w-[100%] rounded-xl"
      />
      <input
        type="text"
        placeholder="Subject"
        className="w-[90%] my-3 sm:h-[20%] sm:w-[100%] rounded-xl"
      />
      <textarea
        name=""
        id=""
        placeholder="Message"
        cols="20"
        readOnly
        className={`w-[90%] my-3 sm:h-[30%] lg:h-[50%] sm:w-[100%]  rounded-xl sm:rows-1`}
      ></textarea>
      <button
        placeholder="Message"
        className="bg-[#4CAF50] xl:h-[30px] w-[90%] sm:w-[100%] h-[10px] sm:h-[30px] md:h-[40px] lg:h-[40px] resize-none 	 text-white rounded-3xl my-3"
      >
        Submit ➡
      </button>
    </div>
  );
}
