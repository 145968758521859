import React from "react";
import { link } from "react-router-dom";

function OurSpecialListSection(props) {
  const specialistCards = [
    {
      title: "Dr. Chetan B. Shah",
      image: "/assert/Doc1.svg",
      description: "Child Specialist",
      additionalData:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit Quisque ipsum neque,eliu suscipit sit amet ma,enu pretium dictum elaun .",
    },
    {
      title: "Dr Neeta C. Shah",
      image: "/assert/Doc2.svg",
      description: "Neonatologist",
      additionalData:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit Quisque ipsum neque,eliu suscipit sit amet ma,enu pretium dictum elaun .",
    },
    {
      title: "Dr Neeta C. Shah",
      image: "/assert/Doc2.svg",
      description: "Neonatologist",
      additionalData:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit Quisque ipsum neque,eliu suscipit sit amet ma,enu pretium dictum elaun .",
    },
    {
      title: "Dr Sachin A. Shah",
      image: "/assert/Doc3.svg",
      description: "Chief Pathologist",
      additionalData:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit Quisque ipsum neque,eliu suscipit sit amet ma,enu pretium dictum elaun .",
    },
    {
      title: "Dr Silcky Modi",
      image: "/assert/Doc4.svg",
      description: "Neonatologist",
      additionalData:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit Quisque ipsum neque,eliu suscipit sit amet ma,enu pretium dictum elaun .",
    },

    // Add more cards...
  ];
  return (
    <div className={`w-full bg-[#D5E4F0] ${!props.toggle ? "mb-4" : "mb-10"}`}>
      {/* Render Specialist Cards */}

      <img
        src="/assert/service-container.svg"
        className=" absolute z-30 overflow-hidden "
        alt=""
      />

      <div className=" w-full ">
        <div className="text-black p-2 ">
          <h1 className="text-4xl text-center sm:text-[20px] text-blue-700 pt-4">
            Our Specialist
          </h1>
          <p className="text-center   text-gray-500 p-4 sm:mb-2 mb-[4em] sm:text-[12px] w-[30em] md:w-[37em] lg:w-[37em] mx-auto">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
            ipsum neque, suscipit sit amet massa eu, pretium dictum massa.
          </p>
          <div className="flex flex-wrap justify-center items-center sm:flex-col ">
            <div className="w-[85%]  flex flex-row justify-center items-center ">
              <div className="flex flex-col sm:flex-col bg-white shadow-md rounded-lg m-2 w-[22%] md:w-[30%] lg:w-[30%]  sm:w-[80%]  mb-[4em] md:mb-[5em]">
                <div className="w-full  bg-sky ">
                  <img
                    src={specialistCards[1].image}
                    alt="Card"
                    className="w-full h-auto mt-[-3em]"
                  />
                </div>
                <div className="w-full  p-4">
                  <h2 className="text-blue-500 text-xl font-semibold mb-2">
                    {specialistCards[1].title}
                  </h2>
                  <p className="text-gray-600">
                    {specialistCards[1].description}
                  </p>
                  <hr className="border-t-2 border-ice my-4" />
                  <p className="text-gray-500 flex flex-wrap w-[14em] md:w-full lg:w-full  xl:w-full">
                    {specialistCards[1].additionalData}
                  </p>
                  <div className="flex items-center ">
                    <a className="text-green-600 hover:underline w-[]">
                      View Profile
                    </a>
                    <img
                      src="/assert/Our_specialist_icon.svg"
                      alt="View Profile Icon"
                      className=" h-[7em] ml-auto sm:hidden"
                    />
                  </div>
                </div>
              </div>
              <div className="flex sm:hidden flex-col sm:flex-row bg-white shadow-md rounded-lg m-2 w-[22%]   md:w-[30%] lg:w-[30%]  mb-[4em] md:mb-[5em]">
                <div className="w-full sm:w-1/4 bg-sky  sm:mb-[4em]">
                  <img
                    src={specialistCards[1].image}
                    alt="Card"
                    className="w-full h-auto mt-[-3em]"
                  />
                </div>
                <div className="w-full sm:w-3/4 md:w-full p-4">
                  <h2 className="text-blue-500 text-xl font-semibold mb-2">
                    {specialistCards[1].title}
                  </h2>
                  <p className="text-gray-600">
                    {specialistCards[1].description}
                  </p>
                  <hr className="border-t-2 border-ice my-4" />
                  <p className="text-gray-500 flex flex-wrap w-[14em] md:w-full lg:w-full  xl:w-full">
                    {specialistCards[1].additionalData}
                  </p>
                  <div className="flex items-center ">
                    <a href="#" className="text-green-600 hover:underline">
                      View Profile
                    </a>
                    <img
                      src="/assert/Our_specialist_icon.svg"
                      alt="View Profile Icon"
                      className=" h-[7em] ml-auto"
                    />
                  </div>
                </div>
              </div>
              <div className="flex sm:hidden   flex-col sm:flex-row bg-white shadow-md rounded-lg m-2 md:w-[30%] lg:w-[30%] w-[22%]  mb-[4em] md:mb-[5em]">
                <div className="w-full sm:w-1/4 bg-sky  sm:mb-[4em]">
                  <img
                    src={specialistCards[1].image}
                    alt="Card"
                    className="w-full h-auto mt-[-3em]"
                  />
                </div>
                <div className="w-full sm:w-3/4 md:w-full p-4">
                  <h2 className="text-blue-500 text-xl font-semibold mb-2">
                    {specialistCards[1].title}
                  </h2>
                  <p className="text-gray-600">
                    {specialistCards[1].description}
                  </p>
                  <hr className="border-t-2 border-ice my-4" />
                  <p className="text-gray-500 flex flex-wrap w-[14em] md:w-full lg:w-full  xl:w-full">
                    {specialistCards[1].additionalData}
                  </p>
                  <div className="flex items-center ">
                    <a href="#" className="text-green-600 hover:underline">
                      View Profile
                    </a>
                    <img
                      src="/assert/Our_specialist_icon.svg"
                      alt="View Profile Icon"
                      className=" h-[7em] ml-auto"
                    />
                  </div>
                </div>
              </div>
              <div className="flex sm:hidden md:hidden lg:hidden flex-col sm:flex-row bg-white shadow-md rounded-lg m-2 w-[22%]  mb-[4em] md:mb-[5em]">
                <div className="w-full sm:w-1/4 bg-sky  sm:mb-[4em]">
                  <img
                    src={specialistCards[1].image}
                    alt="Card"
                    className="w-full h-auto mt-[-3em]"
                  />
                </div>
                <div className="w-full  p-4">
                  <h2 className="text-blue-500 text-xl font-semibold mb-2">
                    {specialistCards[1].title}
                  </h2>
                  <p className="text-gray-600">
                    {specialistCards[1].description}
                  </p>
                  <hr className="border-t-2 border-ice my-4" />
                  <p className="text-gray-500 flex flex-wrap w-[14em] md:w-full  xl:w-full">
                    {specialistCards[1].additionalData}
                  </p>
                  <div className="flex items-center ">
                    <a href="#" className="text-green-600 hover:underline">
                      View Profileggg
                    </a>
                    <img
                      src="/assert/Our_specialist_icon.svg"
                      alt="View Profile Icon"
                      className=" h-[7em] ml-auto  sm:hidden"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex text-center items-center justify-center mt-3">
            <button className="bg-[#4CAF50] w-[10rem] xl:h-[40px] mt-2 p-1 text-center justify-center rounded-3xl text-white font-Poppins text-20 font-semibold">
              See More ➡
            </button>
          </div>
        </div>
      </div>

      {/* Render About Us Cards */}
    </div>
  );
}

export default OurSpecialListSection;
