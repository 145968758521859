import React from 'react'
import { Link } from "react-router-dom";


function MainFacilitySection() {
  return (
    <div className='w-full  flex justify-center items-center mt-5'>
      <div className='w-[70%] mt-2'>
        <div className='card grid grid-cols-3 gap-3 '>
          <div className='flex flex-col text-left justify-center h-[500px] font-poppins '>
            <h1 className=' text-[46px] text-[#2D7AB3] font-bold'>
              Our Facilities
            </h1>
            <span className='w-[] text-[20px]'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ipsum neque, suscipit sit amet massa eu, pretium dictum massa. Curabitur efficitur id magna ac sodales.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ipsum neque.
            </span>
          </div>
          <div className=" bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <img className="rounded-t-lg" src="/Assert/FacilityCardImg1.svg" alt="" />
          <div className="p-5">
            <h3 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              PICU
            </h3>

            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              PICU at Anand Children Hospital is 6 bedded State of the Art Level
              III Pediatric ICU with round the clock availability of Consultant
              Pediatric Intensivist with...
            </p>
            <div className="flex items-center">
              <Link to="#" className="text-green-600 hover:underline">
                View Profile
              </Link>
            </div>
          </div>
        </div>
        <div className=" bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <img className="rounded-t-lg" src="/Assert/FacilityCardImg1.svg" alt="" />
          <div className="p-5">
            <h3 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              PICU
            </h3>

            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              PICU at Anand Children Hospital is 6 bedded State of the Art Level
              III Pediatric ICU with round the clock availability of Consultant
              Pediatric Intensivist with...
            </p>
            <div className="flex items-center">
              <Link to="#" className="text-green-600 hover:underline">
                View Profile
              </Link>
            </div>
          </div>
        </div>
        <div className=" bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <img className="rounded-t-lg" src="/Assert/FacilityCardImg1.svg" alt="" />
          <div className="p-5">
            <h3 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              PICU
            </h3>

            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              PICU at Anand Children Hospital is 6 bedded State of the Art Level
              III Pediatric ICU with round the clock availability of Consultant
              Pediatric Intensivist with...
            </p>
            <div className="flex items-center">
              <Link to="#" className="text-green-600 hover:underline">
                View Profile
              </Link>
            </div>
          </div>
        </div>
        <div className=" bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <img className="rounded-t-lg" src="/Assert/FacilityCardImg1.svg" alt="" />
          <div className="p-5">
            <h3 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              PICU
            </h3>

            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              PICU at Anand Children Hospital is 6 bedded State of the Art Level
              III Pediatric ICU with round the clock availability of Consultant
              Pediatric Intensivist with...
            </p>
            <div className="flex items-center">
              <Link to="#" className="text-green-600 hover:underline">
                View Profile
              </Link>
            </div>
          </div>
        </div>
        <div className=" bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <img className="rounded-t-lg" src="/Assert/FacilityCardImg1.svg" alt="" />
          <div className="p-5">
            <h3 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              PICU
            </h3>

            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              PICU at Anand Children Hospital is 6 bedded State of the Art Level
              III Pediatric ICU with round the clock availability of Consultant
              Pediatric Intensivist with...
            </p>
            <div className="flex items-center">
              <Link to="#" className="text-green-600 hover:underline">
                View Profile
              </Link>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default MainFacilitySection