import React from 'react'
import HomeBoxesSection from "../../layouts/HomeBoxesSection";
function MainAboutSection() {
  return (
    <div className=" text-center justify-center flex sm:items-center ">
      <div className="grid grid-cols-2 sm:grid-cols-1 gap-4 sm:gap-2 sm:m-0 m-4 sm:items-center justify-center sm:w-[90%] w-[70%] ">
        <div className="col-start-1 col-end-3">
          <h1 className="text-4xl sm:text-[20px]  font-bold font-poppins text-center text-[#2D7AB3] sm:pt-1 pt-4">welcome to Anand Hospital</h1>
          <p className="text-center sm:p-0 sm:text-[12px]  text-gray-500 p-4 sm:w-[70%]  w-[37em] mx-auto">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ipsum neque, suscipit
          </p>
        </div>
        <div className="sm:w-[100%] sm:item-center h-auto sm:col-start-1 sm:col-end-3 ">
          <img
            src="/Assert/happy-female-doctor-giving-high-fie-little-boy-who-came-with-father-hospital 1.svg"
            alt=""
            className='sm:w-[100%] sm:'
          />
        </div>
        <div className="flex flex-col sm:w-[100%] w-auto sm:col-start-1 sm:col-end-3  bg-white sm:m-0 m-2 justify-center">
          <span className=" font-sans leading-relaxed sm:w-[100%] text-[#7F7F7F] sm:text-[12px] text-[15px] sm:text-center  text-left mb-3">
            Anand Children Hospital is one of the largest Pediatric Super
            Specialty Hospital of South Gujarat located at the center of the
            Surat City. It is the 1st Pediatric Hospital in Gujarat to get Level
            III (A) NNF (National Neonatology Forum) accreditation. With
            Advanced Neonatal ICU (NICU), Pediatric ICU (PICU), Operational
            Theatre for all Neonatal & Pediatric Surgeries, ENT Clinic,
            Immunization & Vaccination Clinics,Radiology Department,
            Physiotherapy Department, Dental Department and 24 Hours Laboratory,
            Pharmacy, Transport Service it makes us a complete Tertiary Quality
            Care Hospital. Dr. Chetan B. Shah, Chief Pediatrician, leads Anand
            Children Hospital with a big team of Experienced Pediatricians. Our
            mission is "Towards Playful Health & Intact Survival with Humane
            Touch" for all.
          </span>
        </div>
        <div className="col-start-1 col-end-3">
          <HomeBoxesSection />
        </div>
      </div>
    </div>
  );
}

export default MainAboutSection