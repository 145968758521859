import React from 'react';
import { Card, CardContent, CardMedia, Typography, Button } from '@mui/material';
import {} from '../Common/ReviewSection'

function ResponsiveCard({ title, image, description,branch, additionalData }) {
  return (
    <div className="flex flex-col sm:flex-row bg-white shadow-md rounded-lg m-2 md:w-1/4 lg:w-1/4 xl:w-1/4 mb-4 mb-[4em] md:mb-[5em]">
      <div className="w-full sm:w-1/4 bg-sky  sm:mb-[4em]">
        <img src={image} alt="Card" className="w-full h-auto mt-[-3em]" />
      </div>
      <div className="w-full sm:w-3/4 p-4">
        <h2 className="text-blue-500 text-xl font-semibold mb-2">{title}</h2>
        <p className="text-gray-600">{description}</p>
        <hr className="border-t-2 border-ice my-4" />
        <p className="text-gray-500 flex flex-wrap w-[17em]">{additionalData}</p>
        <div className="flex items-center ">
          <a
            href="#"
            className="text-green-600 hover:underline"
          >
            View Profile
          </a>
          <img
            src="/assert/Our_specialist_icon.svg"
            alt="View Profile Icon"
            className=" h-[7em] ml-auto"
          />
        </div>
      </div>


      
    </div>
  );
}






function MainSpecialistSection() {
  

  const specialistCards = [
    {
      title: 'Dr. Chetan B. Shah',
      image: '/assert/Doc1.svg',
      description: 'Child Specialist',
      additionalData: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Quisque ipsum neque,eliu suscipit sit amet ma,enu pretium dictum elaun .'
    },
    {
      title: 'Dr Neeta C. Shah',
      image: '/assert/Doc2.svg',
      description: 'Neonatologist',
      additionalData: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Quisque ipsum neque,eliu suscipit sit amet ma,enu pretium dictum elaun .',
    },
    {
      title: 'Dr Sachin A. Shah',
      image: '/assert/Doc3.svg',
      description: 'Chief Pathologist',
      additionalData: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Quisque ipsum neque,eliu suscipit sit amet ma,enu pretium dictum elaun .',
    },
    {
      title: 'Dr Silcky Modi',
      image: '/assert/Doc4.svg',
      description: 'Neonatologist',
      additionalData: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Quisque ipsum neque,eliu suscipit sit amet ma,enu pretium dictum elaun .',
    },

    {
      title: 'Dr. Partil Shah',
      image: '/assert/Doc1.svg',
      description: 'Pediatric Intensivist',
      additionalData: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Quisque ipsum neque,eliu suscipit sit amet ma,enu pretium dictum elaun .',
    },

    {
      title: 'Dr Charul j Bhutwala',
      image: '/assert/Doc2.svg',
      description: 'Child Specialist',
      additionalData: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Quisque ipsum neque,eliu suscipit sit amet ma,enu pretium dictum elaun .',
    },

    {
      title: 'Dr Devarshi Patel',
      image: '/assert/Doc3.svg',
      description: 'Chief Pathologist',
      additionalData: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Quisque ipsum neque,eliu suscipit sit amet ma,enu pretium dictum elaun .',
    },

    {
      title: 'Dr Kashmira Solanki',
      image: '/assert/Doc4.svg',
      description: 'Neonatologist',
      additionalData: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Quisque ipsum neque,eliu suscipit sit amet ma,enu pretium dictum elaun .',
    },


    {
      title: 'Dr. Silky Moddi',
      image: '/assert/Doc1.svg',
      description: 'Neonatologist',
      additionalData: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Quisque ipsum neque,eliu suscipit sit amet ma,enu pretium dictum elaun .',
    },

    {
      title: 'Dr Rajan Jariwala',
      image: '/assert/Doc2.svg',
      description: 'Child Specialist',
      additionalData: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit Quisque ipsum neque,eliu suscipit sit amet ma,enu pretium dictum elaun .',
    },
    // Add more cards...
  ];
  return (
    <div>

      {/* Render Specialist Cards */}
      <div className='bg-cover  bg-no-repeat w-full' style={{ backgroundImage: `url('/assert/Mediamodifier-Design.svg')` }}>
        <div className="text-black p-2">
          <h1 className="text-4xl text-center text-blue-700 pt-4">Our Specialist</h1>
          <p className="text-center  text-gray-500 p-4 mb-[4em] w-[37em] mx-auto">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ipsum neque, suscipit sit amet massa eu, pretium dictum massa.</p>
          <div className="flex flex-wrap justify-center">
            {specialistCards.map((card, index) => (
              <ResponsiveCard
                key={index}
                title={card.title}
                image={card.image}
                description={card.description}
                additionalData={card.additionalData}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Render About Us Cards */}
      
    </div>
  );
}


export default MainSpecialistSection;
