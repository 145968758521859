import React, { useRef, useState, useEffect } from "react";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import "../../index.css";
// import 'swiper/swiper-bundle.min.css';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules"; // Add necessary modules to SwiperCore

function HomeHeroSection() {
  // const [isactive, setisactive] = useState(1);
  // const swiper = useSwiper();

  const [activeIndex, setActiveIndex] = useState(0);

  setTimeout(() => {
    // if(isactive===3){
    //   setisactive(1)
    // }
    // else{
    //   setisactive(isactive+1)
    // }
    //  console.log(swiper.activeIndex)
  }, 2990);

  // useEffect(() => {
  //   if (swiperRef.current) {
  //     const swiper = swiperRef.current.swiper;
  //     console.log("sjsjsj");
  //     console.log(swiper.activeIndex);
  //   }
  // }, []);

  // const swiperSlide = useSwiperSlide();
  // console.log(swiper.realIndex)

  // useEffect(() => {
  //   console.log(swiper.realIndex)
  // },)

  // const HandleOnchange=()=>{
  //   console.log(swiper.realIndex,"ssss")
  // }

  return (
    <div className="w-full md:h-[400px] h-[550px] sm:h-[350px]  xl:h-[850px] ab ">
      <Swiper
        // onChange={HandleOnchange}
        spaceBetween={30}
        loop={true}
        direction={"vertical"}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        // pagination={{
        //   clickable: true,
        // }}
        modules={[Autoplay, Pagination]}
        className="mySwiper custom-pagination custom-navigation"
        onSlideChange={(swiper) => {
          setActiveIndex(swiper.realIndex);
        }}
      >
        <SwiperSlide>
          <div className=" text-white w-full  sm:h-[350px] md:h-[400px]">
            {" "}
            <img
              src="/Assert/doctor-examining-little-girl-by-stethoscope-1.svg"
              alt=""
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="bg-black text-white w-full sm:h-[350px]  md:h-[400px]">
            <img
              src="/Assert/doctor-examining-little-girl-by-stethoscope-1.svg"
              alt=""
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="bg-black text-white w-full sm:h-[350px]  md:h-[400px]">
            <img
              src="/Assert/doctor-examining-little-girl-by-stethoscope-1.svg"
              alt=""
            />
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="flex p-5 absolute justify-center items-center flex-col w-[50%] left-[25%] sm:w-[80%] md:w-[70%] md:h-[50%] h-[60%] sm:h-[38%] sm:left-[12%] md:left-[20%] sm:top-[15%] md:top-[20%] rounded-2xl border-[#8E929B] border-4  z-20 top-[22%]">
        <span className="text-[#E7E7E7] text-[20px] lg:text-[18px] md:text-[15px] sm:text-[12px]">
          "Most Trusted Pediatric Super Specialty Hospital of South Gujarat"
        </span>
        <span className="text-[55px] text-center font-poppins sm:text-[20px] md:text-[25px] lg:text-[35px] text-white w-[60%] sm:w-full my-4 sm:my-2">
          Welcome to Anand Children Hospital
        </span>
        <span className="text-[#E7E7E7]  text-[26px] md:text-[18px] lg:text-[18px] md:w-[90%] w-[70%] sm:w-full sm:text-[11px] text-center">
          Anand Children Hospital is one of the largest Pediatric Super
          Specialty Hospital of South Gujarat located at the center of the Surat
          City.
        </span>
        <div className="flex relative text-center bottom-[-60px] md:bottom-[-25px] lg:bottom-[-25px] sm:bottom-[-35px] items-center justify-center mt-3">
          <button className="bg-[#4CAF50]  w-[10rem] xl:h-[40px] mt-2 p-1 text-center justify-center rounded-3xl text-white font-Poppins text-20 font-semibold">
            Get a quote ➡
          </button>
        </div>
      </div>

      <div className="p-2 gap-2 items-center justify-center flex flex-col absolute top-[45%] sm:top-[25%] z-50 md:right-[62px] lg:right-[300px] sm:right-[18px] right-[460px] scale-0.5">
        {[0, 1, 2].map((index) => (
          <div key={index}>
            {activeIndex === index ? (
              <img src="Assert/Home_Nav_Dot.svg" alt="" className="h-[25px]" />
            ) : (
              <div className="w-[10px] h-[10px] bg-[green] rounded-lg" />
            )}
          </div>
        ))}
        {/* {swiper === 1 ? (
          <img src="Assert/Home_Nav_Dot.svg" alt="" className="h-[25px]" />
        ) : (
          <div className="w-[10px] h-[10px] bg-[green] rounded-lg   " />
        )}
        {swiper === 2 ? (
          <img src="Assert/Home_Nav_Dot.svg" alt="" className="h-[25px]" />
        ) : (
          <div className="w-[10px] h-[10px] bg-[green] rounded-lg   " />
        )}
        {swiper === 3 ? (
          <img src="Assert/Home_Nav_Dot.svg" alt="" className="h-[25px]" />
        ) : (
          <div className="w-[10px] h-[10px] bg-[green] rounded-lg   " />
        )} */}
      </div>
    </div>
  );
}

export default HomeHeroSection;
