import React, { useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";

import PropTypes from "prop-types";

function Navbar() {
  const [toggle, setToggle] = useState(false);
  const [activePage, setActivePage] = useState('Home');

  const handleSetActivePage = (pageName) => {
    setActivePage(pageName);
  };
  console.log(toggle);
  return (
    <div className="flex z-200 sm:mb-16 w-full  ">
      <nav className="bg-white fixed  md:bg-blue dark:bg-gray-900    w-full z-50 top-0 left-0 border-b  dark:border-gray-600">
        <div className="max-w-screen-xl  flex flex-row items-center justify-evenly sm:justify-between mx-auto sm:p-4 p-3 lg:p-2 xl:p-2">
          <Link to="/" className="flex items-center sm:items-start">
            <img
              src="/Assert/MainLogo.svg"
              className="h-15 mr-3"
              alt="Flowbite Logo"
            />
          </Link>
          {toggle ? (
            <AiOutlineClose
              onClick={() => setToggle(!toggle)}
              className="text-black  xl:hidden lg;hidden  text-3xl  block ml-20"
            />
          ) : (
            <AiOutlineMenu
              onClick={() => setToggle(!toggle)}
              className="text-black  xl:hidden lg:hidden  text-3xl 2xl:hidden block ml-20"
            />
          )}
          {/* Responsive  */}
          <div
            className={`w-[50%] top-[100%] ${toggle ? "right-0" : "right-[-100%]"
              } h-full absolute`}
          >
            <ul className="h-screen mt p-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <NavLink
                  exact
                  to="/"
                  className="block py-2 pl-3 pr-4 text-gray-900 bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500"
                  activeClassName="bg-blue-700 text-white"
                  aria-current="page"
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                  activeClassName="bg-blue-700 text-white"
                >
                  About Us
                </NavLink>
              </li>
              {/* Add NavLink components for other menu items */}
              <li>
                <NavLink
                  to="/gallery"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                  activeClassName="bg-blue-700 text-white"
                >
                  Gallery
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/services"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                  activeClassName="bg-blue-700 text-white"
                >
                  Services
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/blogs"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                  activeClassName="bg-blue-700 text-white"
                >
                  Blogs
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/reviews"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                  activeClassName="bg-blue-700 text-white"
                >
                  Reviews
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                  activeClassName="bg-blue-700 text-white"
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="sm:hidden md:hidden">
            <ul className="flex flex-row p-1 sm:hidden font-medium space-x-3 mt-0 md:border-0 dark:bg-gray-800">
              <li>
                <NavLink
                  to="/"
                  className="block py-2 pl-3 pr-4 text-black bg-blue-700 rounded"
                  aria-current="page"
                  exact
                  onClick={() => handleSetActivePage('Home')} style={{ color: activePage === 'Home' ? 'blue' : 'black', textDecoration: activePage === 'Home' ? 'underline' : 'none' }}

                >
                  Home
                </NavLink>
              </li>
              <li>
                <Link
                  to="/about"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded   md:hover:text-blue-700   dark:border-gray-700"
                  onClick={() => handleSetActivePage('About Us')} style={{ color: activePage === 'About Us' ? 'blue' : 'black', textDecoration: activePage === 'About Us' ? 'underline' : 'none' }}

                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/gallery"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded  md:hover:text-blue-700 md:p-0  dark:border-gray-700"
                  onClick={() => handleSetActivePage('Gallery')} style={{ color: activePage === 'Gallery' ? 'blue' : 'black', textDecoration: activePage === 'Gallery' ? 'underline' : 'none' }}

                >
                  Gallery
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded  dark:border-gray-700"
                  onClick={() => handleSetActivePage('Services')} style={{ color: activePage === 'Services' ? 'blue' : 'black', textDecoration: activePage === 'Services' ? 'underline' : 'none' }}

                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="/blogs"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100   dark:border-gray-700"
                  onClick={() => handleSetActivePage('Blogs')} style={{ color: activePage === 'Blogs' ? 'blue' : 'black', textDecoration: activePage === 'Blogs' ? 'underline' : 'none' }}

                >
                  Blogs
                </Link>
              </li>
              <li>
                <Link
                  to="/reviews"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded  dark:border-gray-700"
                  onClick={() => handleSetActivePage('Reviews')} style={{ color: activePage === 'Reviews' ? 'blue' : 'black', textDecoration: activePage === 'Reviews' ? 'underline' : 'none' }}

                >
                  Reviews
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded  dark:border-gray-700"
                  onClick={() => handleSetActivePage('Contact Us')} style={{ color: activePage === 'Contact Us' ? 'blue' : 'black', textDecoration: activePage === 'Contact Us' ? 'underline' : 'none' }}

                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          <button className="sm:hidden md:hidden bg-transparent justify-center items-center  rounded-3xl border-[#4CAF50FF] border-2 text-[#4CAF50FF] w-[11rem] h-[2.1rem] ">
            Appointment ➡
          </button>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
