import React from "react";
import { Link } from "react-router-dom";

import FacilityCard from "../../layouts/FacilityCard";

function OurFacilitySection() {
  return (
    <div
      className=" flex OurFacilityBg w-full h-[calc(80vh)]  sm:h-[calc(100vh)] md:h-[calc(130vh)] lg:h-[calc(100vh)] sm:justify-center   items-center  "
      style={{
        backgroundImage: "url('/Assert/our-facility-section.svg')",
        backgroundSize: "cover",
      }}
    >
      <div className=" w-[90%] md:w-full lg:w-full sm:w-[100%] sm:h-[100%] md:h-full h-[75%] xl:ml-[250px] flex-row sm:flex-col md:flex-col  flex justify-between gap-1 ">
        <div className="w-[35%] md:w-full  border flex justify-center sm:items-center sm:w-[100%] flex-col text-center">
          <div className="p-5 flex justify-center flex-col text-center sm:items-center ">
            <h3 className="mb-2 text-[#2d7ab3] lg:text-center md:text-center  font-poppins text-3xl font-extrabold leading-20 tracking-wider text-left">
              Our Facilities
            </h3>
            <p className="mb-3 font-normal sm:text-[#333333] md:text-center  lg:text-center sm:text-center text-gray-700 text-left  dark:text-gray-400">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard Lorem Ipsum
              is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard
            </p>
          </div>
        </div>
        <div className=" sm:mt-4  ml-4 flex-row flex sm:w-[97%] w-[65%] md:w-[98%] xl:max-h-[80vh] sm:h-[700px] md:h-full  gap-10 overflow-x-auto md:justify-center sm:overflow-y-hidden hidden-scroll-bar ">
          <div className="w-[33%]  md:w-[46%] sm:w-[33%]  sm:min-w-[65%] sm:h-[420px]  md:h-[401px] lg:h-[401px]  bg-white border  xl:min-w-[35%] border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <img
              className="rounded-t-lg"
              src="/Assert/FacilityCardImg1.svg"
              alt=""
            />
            <div className="p-5">
              <h3 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                PICU
              </h3>

              <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                PICU at Anand Children Hospital is 6 bedded State of the Art
                Level III Pediatric ICU with round the clock availability of
                Consultant Pediatric Intensivist with...
              </p>
              <div className="flex items-center">
                <Link to="#" className="text-green-600 hover:underline">
                  View Profile
                </Link>
              </div>
            </div>
          </div>
          <div className="w-[33%]  md:w-[50%] sm:w-[33%] sm:min-w-[65%] sm:h-[420px]  md:h-[401px] lg:h-[401px] bg-white border xl:min-w-[35%] border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <img
              className="rounded-t-lg"
              src="/Assert/FacilityCardImg1.svg"
              alt=""
            />
            <div className="p-5">
              <h3 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                PICU
              </h3>

              <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                PICU at Anand Children Hospital is 6 bedded State of the Art
                Level III Pediatric ICU with round the clock availability of
                Consultant Pediatric Intensivist with...
              </p>
              <div className="flex items-center">
                <Link to="#" className="text-green-600 hover:underline">
                  View Profile
                </Link>
              </div>
            </div>
          </div>
          <div className="w-[33%] md:w-[46%] lg:h-[401px] sm:w-[33%] sm:h-[420px] sm:min-w-[65%]  md:h-[401px] bg-white border xl:min-w-[35%] border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <img
              className="rounded-t-lg"
              src="/Assert/FacilityCardImg1.svg"
              alt=""
            />
            <div className="p-5">
              <h3 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                PICU
              </h3>

              <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                PICU at Anand Children Hospital is 6 bedded State of the Art
                Level III Pediatric ICU with round the clock availability of
                Consultant Pediatric Intensivist with...
              </p>
              <div className="flex items-center">
                <Link to="#" className="text-green-600 hover:underline">
                  View Profile
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default OurFacilitySection;
