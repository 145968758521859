import React from 'react'
import Navbar from "../components/Common/Navbar";
import Footer from "../components/Common/Footer";
import HeroSection from "../components/Common/HeroSection";
import MainSpecialistSection from "../components/Speacilist/MainSpecialistSection";
import ReviewSection from "../components/Common/ReviewSection";

function OurSpeacialist() {
  return (
    <div>
      <HeroSection title="Our Specialist" />
      <MainSpecialistSection />
      <ReviewSection/>
    </div>
  );
}

export default OurSpeacialist