import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Switch, Route,Link,Routes } from "react-router-dom";
import Home from "./pages/Home"
import AboutUs from "./pages/AboutUs";
import Gallery from "./pages/Gallery";
import Reviews from "./pages/Reviews";
import ContactUs from "./pages/ContactUs";
import OurFacilities from "./pages/OurFacilities";
import OurSpeacialist from "./pages/OurSpeacialist";
import SpeacialistDetail from "./pages/SpeacialistDetail";
import FacilityDetail from "./pages/FacilityDetail";
import Blogs from "./pages/Blogs";
import OurServices from "./pages/OurServices";
import Navbar from "./components/Common/Navbar";
import Footer from "./components/Common/Footer";

function App() {
  return (
<div className="App">
  <Router>
    <Navbar/>
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/about' element={<AboutUs/>} />
      <Route path='/gallery' element={<Gallery/>} />
      <Route path='/services' element={<OurServices/>} />
      <Route path='/blogs' element={<Blogs/>} />
      <Route path='/reviews' element={<Reviews/>} />
      <Route path='/contact' element={<ContactUs/>} />
      <Route path='/our-facilities' element={<OurFacilities/>} />
      <Route path='/our-facilities/:id' element={<FacilityDetail/>} />
      <Route path='/our-specialist' element={<OurSpeacialist/>} />
      <Route path='/our-specialist/:id' element={<SpeacialistDetail/>} />
    </Routes>
    <Footer/>
  </Router>
</div>
 
  );
}

export default App;
