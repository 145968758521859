import React from 'react'
import { useParams } from "react-router-dom"
import { BsArrowRight } from "react-icons/bs"

export default function FacilityDetail() {
    const { id } = useParams();
    return (
        <div className='w-full flex justify-center items-center my-5 '>
            <div className='w-[80%] flex-col flex justify-center items-center p-5 '>
                <div className='flex flex-col justify-center items-center mb-2 '>
                    <h1 className='text-[#2D7AB3] text-[46px]'>
                        NICU
                    </h1>
                    <span className='text-[20px] w-[70%] text-center'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ipsum neque, suscipit sit amet massa eu, pretium dictum massa.
                    </span>
                </div>
                <div className='flex flex-col mt-5'>
                    <div className=' grid grid-cols-2'>
                        <div className=''>
                            <img src="/Assert/facilityDetail1.svg" alt="" className='h-[90%] object-fill' />
                        </div>
                        <div className='flex flex-col ' >
                            <span className='text-[20px] w-[95%]'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ipsum neque, suscipit sit amet massa eu, pretium dictum massa. Curabitur efficitur id magna ac sodales.
                            </span>
                            <span className='text-[20px] w-[95%] '>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </span>
                            <span className='text-[20px] w-[95%]'>
                                Nunc aliquam risus felis, vel porttitor risus suscipit non. Sed bibendum quis erat sed congue.
                            </span>
                            <span className='text-[20px] w-[95%]'>
                            Nunc aliquam risus felis, vel porttitor risus suscipit non. Sed bibendum quis erat sed congue. Donec feugiat augue non luctus tincidunt.
                            </span>

                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <div className='flex flex-col my-3'>
                            <span className='text-black font-bold text-[25px]'>
                                What is so Special About UsExpertise:
                            </span>
                            <span className='text-[20px] flex flex-row items-center'>
                                <BsArrowRight className='text-[#2D7AB3] text-[25px]  ' />
                                Headed by Dr. Sachin A. Shah as Chief Neonatologist with very wide experience of Neonatal ICU of over 12 years.</span>
                            <span className='text-[20px] flex flex-row items-center'>
                                <BsArrowRight className='text-[#2D7AB3] text-[25px]  ' />
                                Experts in Managing ELBW preterm babies with Intact Survival</span>
                            <span className='text-[20px] flex flex-row items-center'>
                                <BsArrowRight className='text-[#2D7AB3] text-[25px]  ' />More than 85% survival of complicated patients on ventilator.</span>
                            <span className='text-[20px] flex flex-row items-center'>
                                <BsArrowRight className='text-[#2D7AB3] text-[25px]  ' />
                                Team of experienced Associate Neonatologist, Fellow Neonatologist and paramedics staff.Pioneers in Use of High Frequency
                            </span>
                            <span className='text-[20px] flex flex-row items-center'>
                                <BsArrowRight className='text-[#2D7AB3] text-[25px]  ' />
                                entilators in South Gujarat.High risk Obstetric Unit for "Inutero Transport" of complicated cases.
                            </span>
                            <span className='text-[20px] flex flex-row items-center'>
                                <BsArrowRight className='text-[#2D7AB3] text-[25px]  ' />Treated more than 10000 sick babies in last 12 years</span>
                            <span className='text-[20px] flex flex-row items-center'>
                                <BsArrowRight className='text-[#2D7AB3] text-[25px]  ' />
                                Experts in Non - Invasive Ventilation with minimal rate of morbidity.Experience:Overall survival in NICU is more than 95%
                            </span>
                            <span className='text-[20px] flex flex-row items-center'>
                                <BsArrowRight className='text-[#2D7AB3] text-[25px]  ' />Intact survival of 28 weeks (1 kg preterm) babies in near 90%
                            </span>
                            <span className='text-[20px] flex flex-row items-center'>
                                <BsArrowRight className='text-[#2D7AB3] text-[25px]  ' />
                                High Frequency Ventilator, Advanced CPAP machines, INO & Brain Cooling facilities
                            </span>
                        </div>
                        <div className='flex flex-col mt-1'>
                            <h1 className='text-black font-bold text-[25px]'>
                                Cost:
                            </h1>
                        </div>
                        <span className='text-[20px] flex flex-row items-center'>
                            <BsArrowRight className='text-[#2D7AB3] text-[25px]  ' />Most Cost Effective Advanced NICU in Private Setup.</span><span className='text-[20px] flex flex-row items-center'>
                            <BsArrowRight className='text-[#2D7AB3] text-[25px]  ' />No Compromise in Quality of Care with Affordable treatment</span><span className='text-[20px] flex flex-row items-center'>
                            <BsArrowRight className='text-[#2D7AB3] text-[25px]  ' />Sources of Funding for Poor & Needy patients.</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
