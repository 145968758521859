import React from 'react'
import Navbar from "../components/Common/Navbar";
import Footer from "../components/Common/Footer";
import HeroSection from "../components/Common/HeroSection";
import MainFacilitySection from "../components/Facilities/MainFacilitySection";
import ReviewSection from "../components/Common/ReviewSection";

function OurFacilities() {
  return (
    <div>
      <HeroSection title="Our Facility" />
      <MainFacilitySection />
      <ReviewSection/>
    </div>
  );
}

export default OurFacilities