import React from "react";
import { CiLocationOn } from "react-icons/ci";
import Icons from "./Icons";

import { Link } from "react-router-dom";

export default function GetinTouchWork() {
  return (
    <div className="w-[40%] md:w-full lg:w-full sm:w-[100%]">
      <h2 className="text-left font-poppins text-[30px] sm:text-[20px] font-semibold  tracking-normal text-blue-500">
        Let’s Work Together!
      </h2>

      <div className="mt-2 w-[90%] gap-4 flex flex-col">
        <div className="flex flex-row mt-4   ">
          <Icons value="location" css="w-[40px]" />
          <span className="text-left text-[20px] sm:text-[12px]  font-poppins font-normal leading-30 tracking-normal ml-2 text-gray-500  ">
            301, Third Floor, 01, Shivalik Western, L.P. Savani Road Adajan
            Surat - 395009
          </span>
        </div>
        <div className="flex flex-row mt-4    ">
          <Icons value="location" css="w-[40px]" />
          <span className="text-left text-[20px] sm:text-[12px]  font-poppins font-normal leading-30 tracking-normal ml-2 text-gray-500  ">
            301, Third Floor, 01, Shivalik Western, L.P. Savani Road Adajan
            Surat - 395009
          </span>
        </div>
        <div className="flex flex-row mt-4 sm:text-[12px]   ">
          <Icons value="location" css="w-[40px]" />
          <span className="text-left text-[20px] sm:text-[12px]  font-poppins font-normal leading-30 tracking-normal ml-2 text-gray-500  ">
            301, Third Floor, 01, Shivalik Western, L.P. Savani Road Adajan
            Surat - 395009
          </span>
        </div>
        <div className="flex items-center mt-4">
          <Link
            to="#"
            className="text-green-600 text-[25px] sm:text-[12px] font-poppins font-bold hover:underline"
          >
            More Contact
          </Link>
        </div>
      </div>
    </div>
  );
}
