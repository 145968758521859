import React from "react";
import { Link } from "react-router-dom";

export default function BlogSection() {
  return (
    <div className="flex justify-center items-center mb-4 text-center">
      <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 sm:grid-cols-1 gap-2 w-[80%] h-[80%] my-3 ">
        <div className=" xl:col-span-3  md:col-span-2 lg:col-span-3">
          <h1 className="text-[30px] text-center text-blue-700  xl:pt-4 font-bold font-poppins">
            Blogs
          </h1>
          <p className="text-center sm:text-[12px]  text-black xl:p-4 xl:mb-[1em] sm:w-[100%] w-[37em] mx-auto">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
            ipsum neque, suscipit.
          </p>
        </div>
        <div className="bg-[#F6FBFF] rounded-2xl my-2 flex items-center justify-center text-center ">
          <div className="grid-cols-1 grid w-[90%] sm:h-[100%] h-[90%] sm:gap-2">
            <div className="w-full text-left flex flex-row gap-2 sm:my-2  ">
              <div>
                <img
                  src="/Assert/HomeBlog1.1.svg"
                  alt=""
                  className="w-[100px] sm:w-[70px] lg:w-[90px]"
                />
              </div>
              <div className="ml-2 gap-2">
                <h3 className="text-[20px] sm:text-[14px] lg:text-[15px] text-[#2D7AB3] font-bold font-poppins ">
                  Pediatric Infectious Diseas
                </h3>
                <h4 className="text-[16px]">25 May, 2023</h4>
                <div className="flex items-center">
                  <Link
                    to="#"
                    className="text-green-600 hover:underline text-[22px] sm:text-[12px] lg:text-[16px]"
                  >
                    View Profile --&gt;
                  </Link>
                </div>
              </div>
            </div>
            <hr />
            <div className="w-full text-left flex flex-row gap-2 sm:my-2 ">
              <div>
                <img
                  src="/Assert/HomeBlog1.1.svg"
                  alt=""
                  className=" w-[100px] sm:w-[70px] lg:w-[90px]"
                />
              </div>
              <div className="ml-2 gap-2">
                <h3 className="text-[20px] sm:text-[14px] lg:text-[15px] text-[#2D7AB3] font-bold font-poppins ">
                  Pediatric Infectious Diseas
                </h3>
                <h4 className="text-[16px]">25 May, 2023</h4>
                <div className="flex items-center">
                  <Link
                    to="#"
                    className="text-green-600 hover:underline text-[22px] sm:text-[12px] lg:text-[16px]"
                  >
                    View Profile --
                  </Link>
                </div>
              </div>
            </div>
            <hr />
            <div className="w-full text-left flex flex-row gap-2 sm:my-2  ">
              <div>
                <img
                  src="/Assert/HomeBlog1.1.svg"
                  alt=""
                  className="w-[100px] sm:w-[70px] lg:w-[90px] "
                />
              </div>
              <div className="ml-2 gap-2">
                <h3 className="text-[20px] sm:text-[14px] lg:text-[15px] text-[#2D7AB3] font-bold font-poppins ">
                  Pediatric Infectious Diseas
                </h3>
                <h4 className="text-[16px]">25 May, 2023</h4>
                <div className="flex items-center">
                  <Link
                    to="#"
                    className="text-green-600 hover:underline text-[22px] sm:text-[12px] lg:text-[16px]"
                  >
                    View Profile --
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:relative    ">
          <div>
            <img
              src="/Assert/HomeBlog1.svg"
              className="sm:h-[400px] sm:w-[345px]"
              alt=""
            />
          </div>
        </div>
        <div className="xl:relative ">
          <div>
            <img
              src="/Assert/HomeBlog2.svg"
              className="sm:h-[400px] sm:w-[345px]"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
