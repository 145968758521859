import React from 'react'
import Navbar from "../components/Common/Navbar";
import Footer from "../components/Common/Footer";
import HeroSection from "../components/Common/HeroSection";
import MainServicesSection from "../components/Services/MainServicesSection";

function OurServices() {
  return (
    <div>
      <HeroSection title="Our Services" />
      <MainServicesSection />
    </div>
  );
}

export default OurServices