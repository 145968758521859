import React from "react";
import GetinTouchWork from "../../layouts/GetinTouchWork";
import GetinTouchForm from "../../layouts/GetinTouchForm";
import { CiLocationOn } from "react-icons/ci";

function GetInTouch() {
  return (
    <div
      className="w-full xl:h-[114vh]  items-center  relative justify-center  p-8 md:p-4 sm:p-0 flex"
      style={{ backgroundImage: `url('/assert/GetInTouchBg.svg')` }}
    >
      <div className=" w-[70%] h-[90%] sm:h-[95%] sm:w-[90%] p-8 sm:p-2">
        <div className=" h-[20%] sm:h-[15%] w-full p-8 sm:p-2">
          <h1 className="text-4xl sm:text-2xl text-center text-blue-700 font-bold xl:pt-2">
            Get in touch
          </h1>
          <p className="text-center  text-gray-500 sm:text-black sm:text-[14px] xl:p-4 mb-[1em] w-[37em] md:w-[24em] sm:w-[90%] mx-auto">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
            ipsum neque, suscipit.{" "}
          </p>
        </div>
        <div className="h-[75%] sm:h-[75%] p-8 md:p-4 rounded-lg bg-white relative w-full flex xl:text-center xl:items-center  justify-center">
          <div className="w-[100%] h-full  flex flex-row sm:flex-col-reverse md:flex-col-reverse lg:flex-col-reverse sm:gap-1  gap-4">
            <GetinTouchWork />
            <GetinTouchForm />
          </div>
          <img
            src="/Assert/Contact-Container.svg"
            alt="sssssssss"
            className="w-[400px] absolute bottom-0 left-0 sm:w-[200px] "
          />
        </div>
      </div>
    </div>
  );
}

export default GetInTouch;
