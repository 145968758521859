import React from "react";

function MainServicesSection() {
  return (
    <div className="flex flex-col items-center justify-center mb-8">
      <div className="mb-1">
        <h1 className="text-4xl sm:text-[20px] font-semibold leading-10 tracking-wider text-center text-blue-600 font-poppins">
          Services
        </h1>

        <p className="text-base font-normal sm:text-[12px] leading-6 text-center text-gray-600 font-poppins">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum is simply.
        </p>
      </div>
      <div className="w-[80%]  flex flex-col gap-7">
        <div className="w-[100%] h-[50%] sm:h-[100%] sm:flex-col-reverse flex flex-row">
          <div className="w-[30%] h-[100%] sm:w-[100%] flex flex-col text-left">
            <h1 className="text-black text-[30px] font-poppins font-bold sm:text-[18px] ">
              All Service{" "}
            </h1>
            <div className="mt-4 ">
              <h1 className="text-[#2D7AB3] text-[25px] font-poppins font-bold sm:text-[16px] my-2">
                Operatin Theater
              </h1>
              <hr className="w-[50%]" />
              <h1 className="text-[black] text-[25px] font-poppins sm:text-[16px] font-bold my-2">
                Emergency Services
              </h1>
              <hr className="w-[50%]" />
              <h1 className="text-[black] text-[25px] font-poppins sm:text-[16px] font-bold my-2">
                Rehabilitation Center
              </h1>
              <hr className="w-[50%]" />
              <h1 className="text-[black] text-[25px] font-poppins sm:text-[16px] font-bold my-2">
                Qualified Doctors
              </h1>
            </div>
          </div>
          <div className="w-[70%] sm:w-[100%] h-[100%] flex ">
            <img src="/Assert/serviceImg1.svg" alt="sm:w-[20px] " />
          </div>
        </div>
        <div className="w-[100%] h-[50%] sm:h-[50%] sm:flex-col  md:flex-col flex flex-row gap-6">
          <div className="w-[60%] sm:w-[100%] h-[100%] ">
            <img
              src="/Assert/serviceImg2.svg"
              alt=""
              className="w-[100%] h-[100%]"
            />
          </div>
          <div className=" h-[100%] w-[45%] md:w-[100%]  sm:w-[100%] flex flex-col text-left">
            <h1 className=" font-poppins text-[25px] sm:text-[16px] text-[#2D7AB3]">
              Operation Theater
            </h1>
            <p className="text-[20px] sm:text-[12px]">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              rutrum, turpis ac tristique consequat, ipsum metus consequat orci,
              a feugiat urna ex vel eros. Vivamus suscipit purus non nunc
              suscipit consequat. Proin sit amet nulla urna.
            </p>
            <p className="text-[20px] sm:text-[12px]">
              Vivamus finibus est diam, et interdum ipsum consequat a. Praesent
              a sem at ligula porta luctus ac vel lorem.
            </p>
            <p className="text-[20px] sm:text-[12px]">
              Morbi volutpat ante vitae orci varius sollicitudin. Suspendisse ut
              vehicula ipsum. Nam consectetur nisi ac euismod pellentesque.
              Suspendisse nec sem nunc. Donec pellentesque justo tellus, a
              consequat neque ultrices eget. Morbi sit amet dictum nibh.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainServicesSection;
