import React from "react";
import {
  AiOutlineInstagram,
  AiFillFacebook,
  AiOutlineTwitter,
} from "react-icons/ai";
import { FaTelegramPlane } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import { IoMdCall } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import Icons from "../../layouts/Icons";

function Footer() {
  return (
    <div className="h-[400px] sm:h-[500px] w-full bg-[rgba(51,51,51,1)] justify-center items-center flex  flex-col">
      <div className="h-[80%] w-[100%] sm:w-[90%] sm:h-[100%]    text-white items-center flex justify-center ">
        <div className="h-[80%] sm:h-[100%] sm:w-[100%] w-[90%] sm:grid  grid-cols-3     flex justify-between sm:flex-col flex-row gap-0.5">
          <div
            className="w-[20%] sm:w-[100%] sm:h-[50px]
           h-[100%] sm:my-4 sm:items-center col-start-1 col-end-4 sm:justify-start  flex flex-col "
          >
            <img src="/Assert/footerLogo.svg" alt="" className="w-[8rem]" />
            <span className="my-2 text-left sm:w-[70%]  sm:text-center  sm:text-[13px] font-poppins  text-[18px]">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has.
            </span>
            <div className="flex sm:hidden flex-col  gap-2">
              <h1 className="my-1 text-left font-poppins sm: text-[18px]">
                Follow us
              </h1>

              <div className="flex flex-row gap-2">
                <Icons value="insta" css="w-[40px] " />
                <Icons value="facbook" css="w-[40px] " />
                <Icons value="telegram" css="w-[40px] " />
                <Icons value="Twitter" css="w-[40px] " />
              </div>
            </div>
          </div>
          <div className=" h-[100%] my-4 sm:h-[70px]  flex flex-col sm:text-left">
            <h1 className=" font-poppins font-semibold sm:text-[16px] text-[18px]">
              Others
            </h1>
            <span className=" font-poppins sm:text-[12px] text-[18px]">
              User{" "}
            </span>
            <span className=" font-poppins sm:text-[12px] text-[18px]">
              FAQs
            </span>
            <span className=" font-poppins sm:text-[12px] text-[18px]">
              Contact Us
            </span>
            <span className=" font-poppins sm:text-[12px] text-[18px]">
              Legal
            </span>
            <span className=" font-poppins sm:text-[12px] text-[18px]">
              Privacy Policy
            </span>
            <span className=" font-poppins sm:text-[12px] text-[18px]">
              Terms and Conditions
            </span>
          </div>
          <div className=" h-[100%] my-4 sm:h-[70px] sm:text-left   flex flex-col">
            <h1 className=" font-poppins font-semibold sm:text-[16px] text-[18px]">
              Quick Links
            </h1>
            <span className=" font-poppins sm:text-[12px] text-[18px]">
              Home{" "}
            </span>
            <span className=" font-poppins sm:text-[12px] text-[18px]">
              About Us
            </span>
            <span className=" font-poppins sm:text-[12px] text-[18px]">
              Contact Us
            </span>
            <span className=" font-poppins sm:text-[12px] text-[18px]">
              Vision & Mission{" "}
            </span>
            <span className=" font-poppins sm:text-[12px] text-[18px]">
              Gallery
            </span>
            <span className=" font-poppins  sm:text-[12px] text-[18px]">
              Announcement
            </span>
            <span className=" font-poppins sm:text-[12px] text-[18px]">
              Contact Us
            </span>
          </div>
          <div className="hidden my-4  sm:h-[70px] sm:text-left   h-[100%] sm:flex flex-col   gap-2">
            <h1 className="xl:my-1 text-left font-poppins m-0 text-white sm:text-[16px] text-[18px]">
              Follow us
            </h1>
            <div className="flex flex-row gap-2">
              <Icons value="insta" css="w-[30px] " />
              <Icons value="facbook" css="w-[30px] " />
              <Icons value="telegram" css="w-[30px] " />
              <Icons value="Twitter" css="w-[30px] " />
            </div>
          </div>
          <div className="sm:hidden h-[100%]  flex flex-col ">
            <h1 className=" font-poppins font-semibold text-[18px]">
              Get in Touch
            </h1>
            <div className="flex flex-row my-2 items-center">
              <Icons value="phone" css="w-[40px] " />
              <span className="ml-2">
                NICU - 99251 00141, PICU - 9879612932
              </span>
            </div>
            <div className="flex flex-row my-2 items-center">
              <Icons value="email" css="w-[40px] " />
              <span className="ml-2">
                NICU - 99251 00141, PICU - 9879612932
              </span>
            </div>
            <div className="flex flex-row my-2 items-center">
              <Icons value="location" css="w-[40px] " />
              <span className="ml-2">
                NICU - 99251 00141, PICU - 9879612932
              </span>
            </div>
          </div>
          <div className="hidden xl:my-4 col-start-1 col-end-4 sm:flex h-[100%]   flex-col ">
            <h1 className=" font-poppins  sm:text-left font-semibold sm:text-[16px] text-[18px]">
              Get in Touch
            </h1>
            <div className="flex flex-row my-2 sm:my-1 items-center">
              <Icons value="phone" css="w-[20px] " />
              <span className="ml-2 sm:text-[12px]">
                NICU - 99251 00141, PICU - 9879612932
              </span>
            </div>
            <div className="flex flex-row my-2  sm:my-1 items-center">
              <Icons value="email" css="w-[20px] " />
              <span className="ml-2 sm:text-[12px]">
                NICU - 99251 00141, PICU - 9879612932
              </span>
            </div>
            <div className="flex flex-row my-2 sm:my-1 items-center">
              <Icons value="location" css="w-[20px] " />
              <span className="ml-2 sm:text-[12px]">
                NICU - 99251 00141, PICU - 9879612932
              </span>
            </div>
          </div>
        </div>
        {/* <div className="flex sm:hidden flex-row  gap-2">
          <h1 className="my-1 text-left font-poppins text-[18px]">Follow us</h1>
          <Icons value="insta" css="w-[40px] " />
          <Icons value="facbook" css="w-[40px] " />
          <Icons value="telegram" css="w-[40px] " />
          <Icons value="Twitter" css="w-[40px] " />
        </div> */}
        {/* <div className="sm:hidden h-[100%]  flex flex-col ">
          <h1 className=" font-poppins font-semibold text-[18px]">
            Get in Touch
          </h1>
          <div className="flex flex-row my-2 items-center">
            <Icons value="phone" css="w-[40px] " />
            <span className="ml-2">NICU - 99251 00141, PICU - 9879612932</span>
          </div>
          <div className="flex flex-row my-2 items-center">
            <Icons value="email" css="w-[40px] " />
            <span className="ml-2">NICU - 99251 00141, PICU - 9879612932</span>
          </div>
          <div className="flex flex-row my-2 items-center">
            <Icons value="location" css="w-[40px] " />
            <span className="ml-2">NICU - 99251 00141, PICU - 9879612932</span>
          </div>
        </div> */}
      </div>
      <hr className="text-white w-[100%]" />
      <div className="h-[2%] sm:h-auto">
        <h3 className="m-2 sm:hidden text-[#B4B4B4] sm:text-[14px]">
          Visitor Count : 87960 | Anand Children Hospital. All right reserved. |
          Developed By : Green Hightech
        </h3>
        <h3 className="m-2 xl:hidden md:hidden  text-[#B4B4B4] text-[14px]">
          Visitor Count : 87960 | Anand Children Hospital
        </h3>
      </div>
    </div>
  );
}

export default Footer;
