import React from "react";

function MainGallerySection() {
  return (
    <div className="flex flex-col items-center justify-center w-full ">
      <div className="col-start-1 col-end-3 my-5  mt-8 sm:mt-2 sm:w-[70%]">
        <h1 className="text-4xl  sm:text-[20px] font-semibold leading-10 tracking-wider sm:mb-0 mb-3 text-center text-blue-600 font-poppins">
          Our Services
        </h1>

        <p className="text-base sm:text-[12px] font-normal  leading-6 sm:leading-1 text-[20px] text-[#333333] text-center font-poppins">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum is simply.
        </p>
      </div>
      <div className="w-[80%]  m-4 flex sm:flex-col flex-row gap-2  ">
        <div className="w-[30%] sm:w-[100%] sm:flex sm:gap-2 sm:flex-row flex-col">
          <div className="h-[45%]  sm:w-[45%] w-[100%] mb-2 ">
            <img
              src="https://res.cloudinary.com/dat5o67ac/image/upload/v1695909335/AanadHospital/yzmxydhr5we7jqpd7op8.svg"
              loading="lazy"
              className=" sm:w-[100%] sm:h-[181px]"
              alt=""
            />
          </div>
          <div className="h-[45%] sm:w-[45%] w-[100%] ">
            <img
              src="/Assert/galleryImg2.svg"
              loading="lazy"
              className="sm:w-[100%] sm:h-[181px] "
              alt=""
            />
          </div>
        </div>
        <div className="w-[30%] sm:w-[100%]  h-full justify-between   ">
          <div className="mb-4 sm:h-[100%] h-[20%] ">
            <img src="/Assert/galleryImg3.svg" loading="lazy" alt="" />
          </div>
          <div className="mb-4 h-[30%] sm:w-[100%] sm:h-[100%] ">
            <img src="/Assert/galleryImg4.svg" loading="lazy" alt="" className="sm:h-[181px] sm:w-[345px]" />
          </div>
          <div className="mb-2 h-[30%] sm:hidden sm:h-[100%]">
            <img
              src="https://res.cloudinary.com/dat5o67ac/image/upload/v1695909349/AanadHospital/if7fxhakdcvpi2cnxmjc.svg"
              loading="lazy"
              alt=""
            />
          </div>
        </div>
        <div className="w-[30%] sm:flex sm:flex-row sm:gap-2 sm:w-[100%] h-full ">
          <div className="h-[50%] w-[100%] mb-2 ">
            <img
              src="https://res.cloudinary.com/dat5o67ac/image/upload/v1695909352/AanadHospital/v2isxs1bqht3xk5k8oqj.svg"
              className="sm:h-[181px]"
              loading="lazy"
              alt=""
            />
          </div>
          <div className="h-[50%] w-[100%] ">
            <img
              src="/Assert/galleryImg7.svg"
              loading="lazy"
              className="sm:h-[181px]"
              alt=""
            />
          </div>
        </div>
      </div>

      {/* <div className="grid w-[80%] grid-cols-4 gap-4">
        <div className='w-[30%] '>
          <div className=''>
            <img src="/Assert/galleryImg1.svg" alt="" />
          </div>
          <div className='h-[50%] w-[100%]'>
            <img src="/Assert/galleryImg1.svg" alt="" />
          </div>
        </div>
        <div>02</div>
        <div>03</div>
        <div>04</div>
      </div> */}
    </div>
  );
}

export default MainGallerySection;
