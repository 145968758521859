import React from 'react'

export default function HomeBoxesSection() {
  return (
    <div className="grid grid-cols-4 sm:grid-cols-2 gap-5   ">
      <div className="bg-[#3771FF]   flex flex-col justify-center items-center  text-white rounded-xl">
        <div className=" w-[80%]  sm:h-[150px] h-[80%] xl:m-6 ">
          <img
            src="/Assert/about-4.svg"
            className="text-left mb-2 mt-2"
            alt=""
            width="30px"
          />
          <h1 className="font-Poppins  text-left font-bold leading-45 mb-1  text-2xl tracking-normal">
            200+
          </h1>
          <h1 className="font-Poppins  text-left font-medium text-xl mb-4  tracking-normal">
            Clients
          </h1>
        </div>
      </div>
      <div className="bg-[#9857D7]   flex flex-col justify-center items-center  text-white rounded-xl">
        <div className=" w-[80%] h-[80%] xl:6 ">
          <img
            src="/Assert/about-3.svg"
            className="text-left mb-2"
            alt=""
            width="30px"
          />
          <h1 className="font-Poppins  text-left font-bold leading-45 mb-1  text-2xl tracking-normal">
            100+
          </h1>
          <h1 className="font-Poppins  text-left font-medium text-xl mb-3  tracking-normal">
            Doctors
          </h1>
        </div>
      </div>
      <div className="bg-[#EF466F]   flex flex-col justify-center items-center  text-white rounded-xl">
        <div className=" w-[80%] h-[80%] xl:m-6 ">
          <img
            src="/Assert/about-2.svg"
            className="text-left mb-2"
            alt=""
            width="30px"
          />
          <h1 className="font-Poppins  text-left font-bold leading-45 mb-1  text-2xl tracking-normal">
            50+
          </h1>
          <h1 className="font-Poppins  text-left font-medium text-xl mb-3  tracking-normal">
            Beds
          </h1>
        </div>
      </div>
      <div className="bg-[#44B26B]   flex flex-col justify-center items-center  text-white rounded-xl">
        <div className=" w-[80%] h-[80%] xl:m-6 ">
          <img
            src="/Assert/about-1.svg"
            className="text-left mb-2"
            alt=""
            width="30px"
          />
          <h1 className="font-Poppins  text-left font-bold leading-45 mb-1  text-2xl tracking-normal">
            24+
          </h1>
          <h1 className="font-Poppins  text-left font-medium text-xl mb-3  tracking-normal">
            Hours Open
          </h1>
        </div>
      </div>
      {/* <div className="bg-[#9857D7] flex flex-col  text-white rounded-xl">
        <img
          src="/Assert/about-2.svg"
          // className="mt-8 ml-10"
          alt=""
          width="25px"
        />
        <h1 className="font-Poppins text-30 font-bold leading-45  text-2xl tracking-normal">
          100+
        </h1>

        <span className="font-Poppins text-18 font-medium text-[25px]  tracking-normal">
          Doctors
        </span>
      </div>
      <div className="bg-[#EF466F] flex flex-col  text-white rounded-xl">
        <img
          src="/Assert/about-3.svg"
          // className="mt-8 ml-10"
          alt=""
          width="25px"
        />
        <h1 className="font-Poppins text-30 font-bold leading-45 text-2xl tracking-normal">
          50+
        </h1>

        <span className="font-Poppins text-18 font-medium text-[25px]  tracking-normal">
          Beads
        </span>
      </div>
      <div className="bg-[#44B26B] flex flex-col  text-white rounded-xl">
        <img
          src="/Assert/about-4.svg"
          // className="mt-8 ml-10"
          alt=""
          width="25px"
        />
        <h1 className="font-Poppins text-30 font-bold leading-45  text-2xl tracking-normal">
          24+
        </h1>

        <span className="font-Poppins text-18 font-medium text-[25px] tracking-normal">
          Hours Open
        </span>
      </div> */}
    </div>
  );
}
