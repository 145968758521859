import React from 'react'
import Navbar from "../components/Common/Navbar";
import Footer from "../components/Common/Footer";
import HeroSection from "../components/Common/HeroSection";
import MainBlogSection from "../components/Blog/MainBlogSection";

function Blogs() {
  return (
    <div>
      <HeroSection title="Blogs" />
      <MainBlogSection />
    </div>
  );
}

export default Blogs