import React from 'react'
import MainAboutSection from "../components/About/MainAboutSection"
import MissionVision from "../components/About/MissionVision"
import Navbar from "../components/Common/Navbar"
import Footer  from "../components/Common/Footer"
import HeroSection  from "../components/Common/HeroSection"
import ServicesSection  from "../components/Common/ServicesSection"
import OurSpecialListSection  from "../components/Common/OurSpecialListSection"


function AboutUs() {
  return (
    <div>
        <HeroSection title="About us"/>
        <MainAboutSection/>
        <MissionVision/>
        <ServicesSection/>
        <OurSpecialListSection toggle="True" />
    </div>
  )
}

export default AboutUs