import React from "react";
import Navbar from "../components/Common/Navbar";
import Footer from "../components/Common/Footer";
import HeroSection from "../components/Common/HeroSection";
import MainReviewSection from "../components/Reviews/MainReviewSection";


function Reviews() {
  return (
    <div>
      <HeroSection title="Review" />
      <MainReviewSection />
    </div>
  );
}

export default Reviews;
