import React from 'react'
import {GoGoal} from "react-icons/go"
import { GiWarlockEye } from "react-icons/gi";
import Icons from "../../layouts/Icons"

function MissionVision() {
  return (
    <div className="w-full sm:w-[100%] sm:h-[400px] h-[200px] bg-[#F8FCFF] flex items-center justify-center">
      <div className="w-[90%] h-[60%] flex sm:flex-col flex-row ">
     
        <div className="flex flex-col sm:flex-row sm:gap-2  ml-2">
          <div className='w-[30%]'>
          <Icons value="mission" css="w-[50px] " />
          </div>
        <div className='flex-col flex w-'>
        <h1 className="font-poppins sm:text-[15px] text-2xl font-semibold leading-7 tracking-normal text-left text-blue-500">
            OUR MISSION
          </h1>
          <span className="my-2 font-poppins text-[13px] sm:text-[12px] text-[#7F7F7F] text-left">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cum
            pretium, ultrices sed faucibus nulla. Egestas scque nat cursus arcu,
            diam.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cum
            pretium, ultrices sed faucibus.
          </span>
        </div>
         
        </div>
        {/* <Icons value="vision" css="w-[30px]" /> */}
     
        <div className="flex flex-col sm:flex-row sm:gap-2  ml-2">
          <div className='w-[30%]'>
          <Icons value="vision" css="w-[50px] " />
          </div>
        <div className='flex-col flex w-'>
        <h1 className="font-poppins sm:text-[15px] text-2xl font-semibold leading-7 tracking-normal text-left text-blue-500">
            OUR VISION
          </h1>
          <span className="my-2 font-poppins text-[13px] sm:text-[12px] text-[#7F7F7F] text-left">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cum
            pretium, ultrices sed faucibus nulla. Egestas scque nat cursus arcu,
            diam.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cum
            pretium, ultrices sed faucibus.
          </span>
        </div>
         
        </div>
      </div>
    </div>
  );
}

export default MissionVision