import React from 'react'
import PropTypes from 'prop-types'

function HeroSection(props) {
  return (
   
      <div
        className=" bg-cover bg-no-repeat bg-center  sm:h-[20vh] sm:w-[100%] w-full md:h-[40vh] xl:h-[65vh] lg:h-[50vh]"
        style={{ backgroundImage: `url('/Assert/headingBg.svg')`, backgroundSize: "cover", }}

      >
        <div className="p-2 text-black">
          <h1 className="text-4xl sm:text-[20px] text-center text-blue-600 xl:mt-[4.75em] sm:mt-[10%] mt-[5em] font-poppins">
            {props.title}
          </h1>
        </div>
      </div>
  
  );
}

export default HeroSection



